const ImageWithPoints = ({ imageUrl, points }) => {
    
    return (
        <div style={{ position: 'relative' }}>
            {/* <img src={imageUrl} alt='Displayed' style={{ width: '100%' }} /> */}
            <img
                src={imageUrl}
                className="w-100"
                alt="image"
                style={{ width: "100%", height: '100%' }}
            />
            {(points?.xPercent || points?.yPercent)  && (
                <div style={{
                    position: 'absolute',
                    top: `${points?.yPercent}%`,
                    left: `${points?.xPercent}%`,
                    width: '15px',
                    height: '15px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%)',
                }} />
            )}
        </div>
    );
};

export default ImageWithPoints;
