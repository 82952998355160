
import { Badge, Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar } from 'reactstrap';
import moment from 'moment';

import { Link, NavLink, Navigate } from 'react-router-dom';

import '../../../scss/dashboardStyles.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import Cart from 'mdi-react/CartIcon';



import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import RecallTabType from '../TabTypes/RecallItemsTabType';
import OrderTabTypes from '../TabTypes/OredrTabTypes';
//Modals
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import ViewModal from '../CommonModals/viewModal';
import BulkUploadModal from '../CommonModals/BulkUploadModal';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import PreviewModal from '../CommonModals/PreviewModal';
import SendEmailModal from '../CommonModals/SendEmailModal';
// fetch method for server call
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import bulkSampleFiles from '../../../config/configSampleFiles'
import apiCall from '../../../config/apiCalls';
//Render Date picker
import RenderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';
// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import InventoryModal from '../CommonModals/OptionsModal';
// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';
//store
import store from '../../App/store'
import validate from '../../Validations/validate'
import { reduxForm, } from 'redux-form'
import { connect } from 'react-redux'
import crypto from "crypto-js";

import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faPrint, faGripHorizontal, faList } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'primereact/button';
import CommonTabTypes from '../TabTypes/CommonTabType';
import WishlistModal from '../CommonModals/WishlistModal/WishlistModal';
import AddQuantitesModal from '../../Form/AddQuantitesModal';
import AddCategoryModal from '../../Form/AddCategoryModal';
import UploadIconModal from '../CommonModals/UploadIconModal';
import apiCalls from '../../../config/apiCalls';
import OrderConfirmationModal from '../CommonModals/OrderConfirmationModal';
import FileGalleryView from './FileGalleryView';


library.add(faCog, faPrint, faGripHorizontal, faList);

// tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
  },
}))(Tooltip);

function DataTables(props) {

  const [allUsersData, setAllUsersData] = useState([]);
  const [deleteRowDataId, setDeleteRowDataId] = useState({});
  const [tableFields, setTableFields] = useState(props.getTableFields());
  const [originalTableFields, setOriginalTableFields] = useState(props.getTableFields());
  const [tablefieldsToShow, setTablefieldsToShow] = useState(props.getTableFields());
  const [isOpenShowHideColumnsModal, setIsOpenShowHideColumnsModal] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortified, setSortified] = useState(false);
  const [direction, setDirection] = useState('asc');
  const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState();
  const [totalRecords, setTotalRecords] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedScreenPermission, setSelectedScreenPermission] = useState('');
  const [loginRole, isLoginRole] = useState('Admin');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [apiUrl, setApiUrl] = useState(props.apiUrl);
  const [totalRecordsLength, setTotalRecordsLength] = useState(0);
  const [tabType, setTabType] = useState(props.tabType);
  const [mobileListFields, setMobileListFields] = useState([]);
  const [dateFormat, setDateFormat] = useState();
  const [selectschool, setSelectschool] = useState();
  const [roleType, setRoleType] = useState();
  const [openId, setOpenId] = useState(1);
  const [redirecToLogin, setRedirecToLogin] = useState(false);
  const [blockFamily, setBlockFamily] = useState(false);
  const [selectActions, setSelectActions] = useState();
  const [viewType, setViewType] = useState('list');
  const [actions, setActions] = useState();
  const [displayBreadCrumbField, setDisplayBreadCrumbField] = useState();
  const [displayBreadCrumbValue, setDisplayBreadCrumbValue] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [globalSearch, setGlobalSearch] = useState();
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState(false);
  const [multiSelectTypes, setMultiSelectTypes] = useState();
  const [actionsTypes, setActionTypes] = useState(props.actionsTypes);
  const [userStatus, setUserStatus] = useState();
  const [selectedAutoCompleteValue, setSelectedAutoCompleteValue] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [confirmModalText, setConfirmModalText] = useState();
  const [confirmType, setConfirmType] = useState();
  const [emailTemplateData, setEmailTemplateData] = useState();
  const [redirectToRoute, setRedirectToRoute] = useState(false);
  const [searchInDateRangeField, setSearchInDateRangeField] = useState(props.searchInDateRangeField ? props.searchInDateRangeField : "created");
  const [fieldMap, setFieldMap] = useState({});
  const [formType, setFormType] = useState();
  const [page, setPage] = useState(1);
  const [sortCount, setSortCount] = useState();
  const [confirmText, setConfirmText] = useState();
  const [leaveStatus, setLeaveStatus] = useState();

  const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [isOpenProductsModal, setIsOpenProductsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [displayViewOfForm, setDisplayViewOfForm] = useState(props.displayViewOfForm);
  const [openUserPasswordResetModal, setOpenUserPasswordResetModal] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [tableRowData, setTableRowData] = useState({});
  const [categoryType, setCategoryType] = useState('Medicine');
  const [cartSubLocation, setCartSubLocation] = useState('EntireCart');
  const [isCategory, setIsCategory] = useState(false);
  const [isOpenWishlist, setIsOpenWishlist] = useState(false);
  const [openOrderConfirmModal, setOpenOrderConfirmModal] = useState(false);
  // const[cartCount ,  setCartCount] = useState(props.cartCount)
  const [storageUnitId, setStorageUnitId] = useState(props.yourQueryParam);
  const [isOpenQuantitesModal, setisOpenQuantitesModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openStorageUnitModal, setOpenStorageUnitModal] = useState(false);
  const [openRecommendedInventoriesModal, setOpenRecommendeInventoriesModal] = useState(false);
  const [loginRoleType, setLoginRoleType] = useState(props.loginRoleType);
  const [entireCartOptions, setEntireCartOptions] = useState([]);
  const [storageUnitSubLocations, setStorageUnitSubLocations] = useState('');
  const [cartListCount, setCartListCount] = useState(0);
  const [type, setType] = useState();
  const [ordersData, setOrdersData] = useState([])
  const loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"))
  const [ordersResponse, setOrdersResponse] = useState()
  const csvLinkRef = useRef();
  const [dirty, setDirty] = useState(false);

const formModalRef = useRef()

  useEffect(() => {
    getLoginRole();
    readData();
    getFieldTypes();
    // getActions();
    let colOrder = localStorage.getItem(`${props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    if (columns) {
      getTableFieldsOrder();
    } else {
      updateColumnsOrder(null);
    }
    // getCartIncrement();

  }, []);

  useEffect(() => {
  }, [ordersData]);

  useEffect(() => {
    getActions();
  }, [selectedRows]);


  const readData = async () => {
    let data = await getDataFromServer(filterCriteria);
    if (props.addSelectedRecord) {
      openFormModal('', 'add')
    } else if (props.viewSelectedRecord) {
      getActions();
      openFormModal('', 'view')
    } else if (props.editSelectedRecord) {
      openFormModal('', 'edit')
    }
  }

  // incresing cart count
  const getCartIncrement = () => {
    let storageUnitId = props.yourQueryParam ? props.yourQueryParam : null
    let api = 'cartLists'
    let url = `${api}?StorageUnitId=${storageUnitId}`

    return fetchMethodRequest('GET', url).then(async (response) => {
      if (response && response.cartlists) {
        let products = response.cartlists[0].products ? response.cartlists[0].products : []
        setCartCount(products.length)
      }
    }).catch((err) => {
      return err
    })
  }

  const getFieldTypes = async () => {
    let tableFields = await props.getTableFields();
    let fieldMap = {};
    tableFields.forEach((col) => {
      fieldMap[col.field] = col.fieldType ? col.fieldType.toLowerCase() : col.type == "number" ? col.type : null;

    });
    setFieldMap(fieldMap)
  }

  const getActions = async () => {
    let selRows = selectedRows ? [...selectedRows] : [];
    let actionsTypes = props.actionsTypes ? [...props.actionsTypes] : [];
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selRows && selRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 0) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }

    setActionTypes(result);
  }



  const getMultiOrdersData = async () => {
    const loginCredentials = localStorage.getItem("loginCredentials");
    const credentialsObject = JSON.parse(loginCredentials);
    let loginRole = credentialsObject.role;
    const companyId = credentialsObject._id;
    if (ordersData && ordersData.length > 0) {
      // Extracting locationId and facilityName from the first order
      const { storageUnitId, facilityName, storageUnitName, facilityId, companyId } = ordersData[0];
      // Define the body object
      const body = {
        companyId: loginRole === 'Admin' ? companyId : loginRole === 'Super Admin' ? props.companyId : null,
        facilityName: facilityName,
        storageUnitName: storageUnitName,
        storageUnitId: storageUnitId,
        facilityId: facilityId,
        products: ordersData.map(order => {
          const { ...productData } = order;
          return productData;
        })
      };

      // Update ordersResponse state
      setOrdersResponse(body);

      let apiUrl = `${apiCalls.multiOrders}`;
      try {
        const response = await fetchMethodRequest('POST', apiUrl, body);
        if (response && response.respMessage) {
          showToasterMessage(response.respMessage, "success");
          getDataFromServer(filterCriteria);

        } else {
          showToasterMessage(response.errorMessage, 'error');
          getDataFromServer(filterCriteria);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setIsLoading(false);
      }
    } else {
      console.error("No orders to send.");
      // Handle the case when there are no orders to send
    }
  };





  const handleClickOutside = (event) => {
    if (
      closeMultiSelectDropdown.current &&
      !closeMultiSelectDropdown.current.contains(event.target)
    ) {

      setShowMultiSelectDropDown(false);
    }
  };
  /**not using For Now */
  const updateDimensions = async () => {
    // await setState({ width: window.innerWidth, height: window.innerHeight });
    setWidth(window.innerWidth);
    setHeight(window.height);
  };
  /**not using For Now */
  const getTimeFormat = () => {
    // let store=store.getState()
    // let dateFormat=store && store.commonData && store.commonData.data && store.commonData.data.timeFormat?store.commonData.data.timeFormat:'DD-MM-YYYY'
  }
  const setEncryptFields = async () => {
    let encryptFields = [];
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].fieldType == "encryptedField") {
        encryptFields.push(tablefieldsToShow[i]['field']);
      }
    }
    setEncryptFields(encryptFields)
  }
  // Handle Table fields to show in datatable
  const getTableFieldsOrder = () => {
    setTablefieldsToShow([]);
    setIsLoading(true);

    let colOrder = localStorage.getItem(`${props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...originalTableFields];

    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    } else {
      tempTableFields = originalTableFields
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map(item => {
        // TableFieldsToShow.push(item)
        if (item.show && viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }
    setTablefieldsToShow(TableFieldsToShow);
    setIsLoading(false);
  }
  const changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      setFilterCriteria(filterCriteria);
      await onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await onMultiSelectFilterChange(key, value, 'push', index)
      }
    }
  }
  const changeViewType = async (viewType) => {
    setViewType(viewType);
    await getTableFieldsOrder()
  }

  const sendEmail = () => {
    let userBody = selectedRows;
    if (userBody) {
      let requestBody = { orders: userBody };

      return fetchMethodRequest('POST', 'orders/sendEmail', requestBody)
        .then((response) => {
          if (response && response.respCode && response.respCode === 200) {
            showToasterMessage(response.respMessage, 'success');
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
          getDataFromServer(filterCriteria, 'Orders List');
        })
        .catch((err) => {
          return err;
        });
    }
  };
  // Handle Table fields order of display in DataTable
  const updateColumnsOrder = (currentOrder) => {
    setIsLoading(true);
    let orgTableFields = [...originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...orgTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          orgTableFields.map(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile,
                  label: col.label ? col.label : col.header
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false,
              label: col.label ? col.label : col.header
            }
          )
        })
      }
    } else {
      orgTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });
    }

    localStorage.setItem(`${props.type}_column_order`, JSON.stringify(updatedOrder));
    getTableFieldsOrder(orgTableFields);
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "DD-MM-YYYY"
    if (state.dateFormat != dateFormat) {
      return { dateFormat: dateFormat };
    }
    return { dateFormat: dateFormat };

  }

  const getLoginRole = () => {
    let loginData = localStorage.getItem('loginCredentials');
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        setLoginRoleType(loginData.role);
      }
    }
  }

  const updateSlNoToData = (itemdata, text) => {
    let modifiedData = [];
    let txt = text + 1;

    itemdata.forEach((item, index) => {
      if (props.flags) {
        let flags = props.flags
        if (item[flags['label']] == flags['value']) {
          modifiedData.push(item);
        }
      }

      else {
        item.Sno = txt;
        txt++;

        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  const updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]['fieldType']) {
          itemdata[i][tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][tablefieldsToShow[k]['field']],
              tablefieldsToShow[k]['dateFormat'] ? tablefieldsToShow[k]['dateFormat'] : dateFormat);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //get table list data from server with filters if any
  const getDataFromServer = async (filterCriteria, type) => {
    if (props.tabType && !type && ((["storageUnitItems", "inventoryChecklist", "missingItems", "expirationItems", "requiredInventories", "OrdersCount"].includes(props.frontScreenResponseKey)) || props.type === "Storage Units")) {
      return
    }
    if (["MissingItems", "Expired Items", "Inventory Check Lists", "OrdersCount"].includes(props.type)) {
      // props.getCartIncrement();
    }

    let url = getAPIUrl();
    let dateFormat = props.dateFormat
    let loginData = JSON.parse(localStorage.getItem('loginCredentials'));

    if (url) {
      let apiUrl;
      setIsLoading(true);
      setSelectedRows('');
      if (type == 'refresh') {
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
          document.getElementById('globalSearch').value = '';
        }
        let fieldsToShow = tablefieldsToShow;
        if (fieldsToShow && fieldsToShow.length > 0) {
          for (let i = 0; i < fieldsToShow.length; i++) {
            let options = fieldsToShow[i].options;
            if (options && options.length) {
              for (let j = 0; j < options.length; j++) {
                options[j]['checkIcon'] = false
              }
            }
          }
        }
        setGlobalSearch('');
        setAllUsersData([]);
        setRoleType('');
        setTablefieldsToShow(fieldsToShow);

      }
      if (!filterCriteria || !filterCriteria['criteria']) {
        // let filterCriteria = {};
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
      }
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        delete filterCriteria['sortfield'];
        delete filterCriteria['direction'];
      }
      if (type === 'refresh' && filterCriteria) {
        delete filterCriteria.globalSearch;
        filterCriteria['criteria'] = [];
        filterCriteria['direction'] = 'desc';
        filterCriteria['sortfield'] = 'created';
      }
      if (props.params) {
        let params = props.params;
        for (let i in params) {
          if (i) {
            let obj = {};
            obj['key'] = i;
            obj['value'] = params[i];

            // Log the params and obj for debugging

            if (["Battery Checks", "Storage Unit Checks", "Recall Items", "Employees",'Activities'].includes(props.type)) {
              if (obj.key === 'storageUnitName') {
                obj['type'] = 'eq';
              } else if (obj.key === 'name' && props.type === 'Battery Checks') {
                obj['type'] = 'ne';
              } else {
                obj['type'] = 'eq'; // Default type for other keys if necessary
              }
              filterCriteria['criteria'].push(obj);

            } else if (props.type === "Products") {
              let isDuplicate = filterCriteria['criteria'].some(existingCriteria =>
                existingCriteria.key === obj.key && existingCriteria.value === obj.value
              );
              if (!isDuplicate) {
                filterCriteria['criteria'].push(obj);
              }
            } else {
              filterCriteria['criteria'].push(obj);
            }
          }
        }
      }

      //applyng filter when a row item is deleted 
      if (filterCriteria == undefined) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (props.filterExtension) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${props.filterExtension}`
      }
      else if (props.hasQueryInCall) {
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      }
      else {
        // apiUrl = getFilterUrl(filterCriteria, type);

        //Super Admin Data
        if (props.companyId && loginData.role === "Super Admin") {
          apiUrl = `${url}?companyId=${props.companyId}&filter=${JSON.stringify(filterCriteria)}`;
        }
        else {
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        }
      }
      if (props.type === "Products") {
        type = type ? (type === "refresh" ? categoryType : type) : categoryType;

        if (window.location.href.includes("redirect")) {
          apiUrl = `${url}?categoryType=${type}&companyId=${props.params.companyId}&filter=${JSON.stringify(filterCriteria)}`;
        } else {
          apiUrl = `${apiUrl}&categoryType=${type}`;
        }
      } 
      const path = window.location.pathname;
      if (props.type === "Battery Checks" && path === '/batteryChecks') {
        let filterCriteria = { limit: 20, page: 1, criteria: [{"key": "name", "value": "Maintenance", "type": "ne"}], sortfield: 'created', direction: 'desc' }
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;

      }

      if (props.type === 'Storage Unit Checks' && path === '/storageUnitChecks'){
        let filterCriteria = { limit: 20, page: 1, criteria: [{ "key": "name", "value": "Maintenance", "type": "eq" }], sortfield: 'created', direction: 'desc' }
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }

      if (props.type === 'Employees' && window.location.href.includes("redirect")) {
        const loginCredentials = localStorage.getItem("loginCredentials");
        const credentialsObject = JSON.parse(loginCredentials);
        const loginRole = credentialsObject.role;
        filterCriteria.loginRole = loginRole;
        filterCriteria.type = 'eq';
        // apiUrl = `${url}&filter=${JSON.stringify(filterCriteria.loginRole)}`;
        apiUrl = `${apiUrl}`;

      }
      // sending selected params as a query
      if (props.yourQueryParam) {
        type = type ? type == "refresh" ? categoryType : type : categoryType;
        apiUrl = `${url}?storageUnitId=${props.yourQueryParam}&companyId=${props.companyId}&categoryType=${type}&filter=${JSON.stringify(filterCriteria)}`;
      }

      if (props.type == "Storage Units") {
        const storageTypes = { "Cart Location": 'cartLocation', "Supply Cabinets": 'supplyCabinet', "OR Suites": 'orSuit' };
        apiUrl = `${apiUrl}&storageUnitType=${storageTypes[type]}`;
      } else if (props.type == "Storage Units") {
        apiUrl = `${apiUrl}&storageUnitType=${storageTypes[props.tabType]}`;
      }

      if (props.type == "Orders") {
        type = type ? type == "refresh" || type === 'search' ? categoryType : type : categoryType;

        setIsLoading(false);

        if (type && (type === 'Orders List' || type === "refresh" && tabType === "Orders List")) {
          apiUrl = (type && (type == 'refresh' || type == "Orders List")) ? `${apiCall.orderlist}?type=unchecked&companyId=${props.companyId}&storageUnitId=${props.storageUnitId}&categoryType=${categoryType}&filter=${JSON.stringify(filterCriteria)}` : null;
          // setIsLoading(false);
        }
        else if ((type && (type === 'Placed Orders') || (type === "refresh" && tabType === 'Placed Orders') || (type === "search" && tabType === 'Placed Orders'))) {
          apiUrl = (type && type == 'refresh' || (type == "Placed Orders") || (type === 'search' && tabType === 'Placed Orders')) ? `${apiUrl}&type=checked&storageUnitId=${props.storageUnitId}&categoryType=${categoryType}` : null;
          // setIsLoading(false);

        } else if ((type && (type === 'Fulfilled Orders') || (type === "refresh" && tabType === 'Fulfilled Orders') || (type === "search" && tabType === 'Fulfilled Orders'))) {
          apiUrl = (type && type == 'refresh' || (type == "Fulfilled Orders") || (type === "search" && tabType === 'Fulfilled Orders')) ? `${apiUrl}&type=fulfilled&storageUnitId=${props.storageUnitId}&categoryType=${categoryType}` : null;
          // setIsLoading(false);

        }
        else {
          if (tabType == 'Orders List') {
            apiUrl = `${apiCall.orderlist}?type=${['unchecked']}&companyId=${props.companyId}&storageUnitId=${props.storageUnitId}&categoryType=${type}&filter=${JSON.stringify(filterCriteria)}`;
          } else if (tabType === 'Placed Orders') {
            apiUrl = `${apiCall.placedOrders}?type=${['checked']}&storageUnitId=${props.storageUnitId}&categoryType=${type}&filter=${JSON.stringify(filterCriteria)}`;
          } else if (tabType === 'Fulfilled Orders')
            apiUrl = `${apiCall.fulfilledOrders}?type=${['fulfilled']}&storageUnitId=${props.storageUnitId}&categoryType=${type}&filter=${JSON.stringify(filterCriteria)}`;

        }
      }
      let limit = filterCriteria.limit;
      let page = filterCriteria.page;
      return fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          let apiResponseKey = props.apiResponseKey;
          let frontScreenResponseKey = props.frontScreenResponseKey;
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            setSessionExpiryModal(true);
          }
          let responseData = [];
          let recordsLength = 0;
          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            recordsLength = response.details.length;
          } else {
            let apiResponseData = []
            if (response && ["OrdersCount", "storageUnitItems", "inventoryChecklist", "missingItems", "expirationItems", "requiredInventories",].includes(frontScreenResponseKey) && frontScreenResponseKey.length !== 0) {

              const storageTypes = { "Cart Location": 'cartLocation', "Supply Cabinets": 'supplyCabinet', "OR Suites": 'orSuit' };
              // const recalledTypes = { "Orders List": 'orderList', "Placed Orders": 'placedOrders', "Fulfilled Orders": 'fullFilledOrders' };
              // let recalledTab = recalledTypes[type]
              let selectedTab = storageTypes[type]
              if (response.pagination && response.pagination.totalCount) {

                recordsLength = response.pagination.totalCount;
              }
              apiResponseData = response[frontScreenResponseKey][selectedTab]
            }

            else if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
              if (response.pagination && response.pagination.totalCount) {
                recordsLength = response.pagination.totalCount;
              }
              apiResponseData = response[apiResponseKey]
            } else {
              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                recordsLength = response.pagination.totalCount;
              }
            }
            if (response && response['storageUnitSubLocations']) {
              setEntireCartOptions(response['storageUnitSubLocations'].map(cartName => {
                return { label: cartName, value: cartName };
              }));
            }
            if (response && response['cartListCount'] || response && response['cartListCount'] == 0) {
              setCartListCount(response['cartListCount'])
            }

            if (apiResponseData && apiResponseData.length > 0) {
              responseData = updateSlNoToData(apiResponseData, limit * (page - 1));
              responseData = updateDateFormat(responseData, dateFormat);
            }
          }
          setAllUsersData(responseData);
          setIsLoading(false);
          if (filterCriteria.page === 1) {
            setTotalRecordsLength(recordsLength);
          }
          return responseData;
        }).catch((err) => {
          return err;
        });
    }
  }


  const getAPIUrl = () => {
    return props.apiUrl;
  }
  const getFilterUrl = (filterCriteria, type) => {
    return props.apiUrl;
  }

  //Get all data of current screen with filters applied from server to export to CSV
  const getDataToExport = async () => {
    setIsLoading(true);
    let filter = { ...filterCriteria };
    delete filter['limit'];
    delete filter['page'];
    let url = getAPIUrl();
    let apiUrl;
    apiUrl = `${url}?filter = ${JSON.stringify(filter)}&type=exportToCsv`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true)
        }
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            for (let key in item) {
              if (item[key] && item[key].name) {
                item[key] = item[key].name;
              }
            }
          });
          setExportData(exportData);
          setIsLoading(false);
          csvLinkRef.current.link.click();
        }
      }).catch((err) => {
        setIsLoading(false);
        return err;
      });
  }

  // wishlist orderconfirm modal
  const getOrderConfirmationModal = (item) => {
    if (item) {
      if (item.isOrdered) {
        setTableRowData(item);
        setOpenOrderConfirmModal(true)
      } else {

        openFormModal(item, "edit", true)

      }
    }

  }



  //open Form modal
  const openFormModal = async (rowData, type, wishlist) => {
    setFormType(type ? type : 'add');
      
    if (props.type === 'Recommended Inventories') {
      localStorage.setItem("ProductRowData", JSON.stringify(rowData));
      setIsOpenFormModal(false);
      setRedirectToRoute(true);
      localStorage.removeItem('products');
      if (type !== "add") {
        localStorage.setItem('minInventoryByStateName', (rowData.minInventoryByStateName ? rowData.minInventoryByStateName : 'add'))
      }
      if (props.type === 'add') {
        localStorage.removeItem('products');
      }

      //products
    }
    else {
      if (props.type === 'Orders' && tabType === 'Orders List') {
        setTableRowData(rowData);
        setIsOpenFormModal(true);

      }
      if ((props.type === "Inventory Check Lists" && !wishlist) || props.type === "Product Quantities") {
        setTableRowData(rowData);
        setisOpenQuantitesModal(true)
      } else if (props.type === "Products") {
        setTableRowData(rowData);
        setOpenCategoryModal(true)
      }
      else {
        setIsOpenFormModal(true);
      }

      setSelectedRows('');

      if (type === 'view') {
        setSelectedRowsId
        setTableRowData(rowData);
        if (isOpenFormModal && formModalRef) {
          await formModalRef.getViewData(rowData);
        }
      }
      if (type === 'edit') {
        setTableRowData(rowData);
        if (isOpenFormModal && formModalRef) {
          await formModalRef.getRowData(rowData, 'edit');
        }
      }
    }
  }

  //Open StorageUnit Upload Icon Modal
  const openStorageUnitItemsModal = (rowData) => {
    setTableRowData(rowData);
    setOpenStorageUnitModal(true)
  }

  //setOpenRecommendeInventoriesModal
  const OpenRecommendedInventoryModal = (rowData) => {
    setTableRowData(rowData);
    setOpenRecommendeInventoriesModal(true)
  }
  //close form modal
  const closeFormModal = async () => {
    if (["Inventory Check Lists", "Product Quantities", "Min Inventory By Products", "MissingItems", "Expired Items", "Orders", "Storage Units"].includes(props.type)) {
      setIsOpenFormModal(false);
    } else {
      setIsOpenFormModal(false);
      setRedirectToRoute(loginCredentials.role === "Super Admin" ? false : true);
    }
    setDirty(false);

  }

  const submitActionsData = (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then((response) => {
        let sessionexpired = localStorage.getItem('sessionexpired');
        if (sessionexpired === "true") {
          setSessionExpiryModal(true);
        }

        setOpenDeleteModal(false);
        setActions('');
        setSelectedRows('');
        setSelectedRowsId('');

        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');

          if (props.type === "Storage Units") {
            getDataFromServer(undefined, props.tabType);
          } else if (props.type === 'Orders' && tabType === 'Fulfilled Orders') {
            getDataFromServer(filterCriteria, 'Fulfilled Orders');

          } else {
            getDataFromServer(undefined, categoryType);
          }
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch((err) => {
        console.error('Error:', err);
        return err;
      });
  };

  const getDeleteInventory = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true);
        }
        setOpenDeleteModal(false);
        setActions('');
        setSelectedRows('');
        setSelectedRowsId('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          getDataFromServer(filterCriteria, categoryType);
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  const handleActions = async (apiType) => {
    let ids = selectedRows.map(id => id._id)
    let method, url, body = {}

    if (props.type === "Inventory Check Lists") {
      let apiUrl = "productQuantities/deleteProductQuantity";
      let ID = selectedRows.map(id => id.storageUnitId);
      url = `${apiUrl}?type=delete`;
      method = "POST";
      const loginCredentials = localStorage.getItem("loginCredentials");
      const credentialsObject = JSON.parse(loginCredentials);
      let loginRole = credentialsObject.role;
      const companyId = credentialsObject._id;
      body = {
        storageUnitId: selectedRows[0].storageUnitId,
        productId: selectedRows[0].productId,
        companyId: loginRole === 'Admin' ? companyId : loginRole === 'Super Admin' ? props.companyId : null,
        loginRole: loginRole

      };
      getDeleteInventory(method, url, body);
    }
    else if (props.type === "Product Quantities") {
      let apiUrl = "productQuantities"
      url = `${apiUrl}/${selectedRows[0]._id}`
      method = "DELETE"
      getDeleteInventory(method, url);
    }
    else if (props.type === "Orders" && tabType === 'Orders List') {
      let apiUrl = "orders/deleteOrderList?multiple=true"
      let ID = selectedRows.map(id => id.storageUnitId)
      url = `${apiUrl}`;
      method = "POST"
      const loginCredentials = localStorage.getItem("loginCredentials");
      const credentialsObject = JSON.parse(loginCredentials);
      let loginRole = credentialsObject.role;
      const companyId = credentialsObject._id;
      body = {
        "Orders": [{
          _id: selectedRows[0]._id,
          productName: selectedRows[0].productName,
          description: selectedRows[0].description,
          distributorEmail: selectedRows[0].distributorEmail,
          productId: selectedRows[0].productId,
          facilityId: selectedRows[0].facilityId,
          companyId: loginRole === 'Admin' ? companyId : loginRole === 'Super Admin' ? props.companyId : null,
          loginRole: loginRole,
          storageUnitId: selectedRows[0].storageUnitId,
          storageUnitName: selectedRows[0].storageUnitName,
        }]
      }
      getDeleteInventory(method, url, body);
    } else if (props.type === "Companies") {
      // http://localhost:2546/api/companyUsers/65fd67992e711a4c092cb5c9
      let Id = selectedRows[0]._id

      let apiUrl = `companyUsers/${Id}`
      let ID = selectedRows.map(id => id.storageUnitId)
      url = `${apiUrl}`;
      method = "DELETE"
      body = {
        _id: selectedRows[0]._id,
      }
      getDeleteInventory(method, url, body);
    } else if (props.type === "Facility Staffs") {
      // http://localhost:2546/api/companyUsers/65fd67992e711a4c092cb5c9
      let Id = selectedRows[0]._id

      let apiUrl = `companyUsers/${Id}`
      let ID = selectedRows.map(id => id.storageUnitId)
      url = `${apiUrl}`;
      method = "DELETE"
      body = {
        _id: selectedRows[0]._id,
      }
      getDeleteInventory(method, url, body);
    }
    else {
      if (confirmType === 'Delete' || confirmType === 'Block' || confirmType === 'UnBlock') {
        url = `${apiUrl}/${apiType}`;
        method = 'POST';
        const loginCredentials = localStorage.getItem("loginCredentials");
        const credentialsObject = JSON.parse(loginCredentials);
        let loginRole = credentialsObject.role;
        const companyId = credentialsObject._id;
        body = {
          selectedIds: ids
        }
      }
      // if (props.type === 'Products') {
      //   const loginCredentials = localStorage.getItem("loginCredentials");
      //   const credentialsObject = JSON.parse(loginCredentials);
      //   let loginRole = credentialsObject.role;
      //   const companyId = credentialsObject._id;
      //   body = {
      //     selectedIds: ids,
      //     loginRole: loginRole,
      //     companyId: props.companyId
      //   }

      // }
      submitActionsData(method, url, body)
    }
  }
  // delete selected row
  const deleteSelectedRow = async () => {
    // if (props.type === 'Orders') {
    //   handleActions('deleteOrderList')
    // } else {
    //   handleActions('multiDelete')
    // }
    if (confirmType === 'Delete') {
      handleActions('multiDelete')
      if (props.tabType === 'Fulfilled Orders') {
        apiUrl = `${apiUrl}&orders=${recalledTypes[type]}`;
      }
      // if (props.type === 'Products') {
      // }
    } else if (confirmType === 'Block') {
      handleActions('multiblock?block=true')
    } else if (confirmType === 'UnBlock') {
      handleActions('multiblock?unblock=true')
    } else if (confirmType === 'ResetPassword') {
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    }
  }

  //close delete modal
  const closeDeleteModal = async () => {
    setOpenDeleteModal(false);
    setActions('');
  }
  const setDeleteRecords = async (rowData, selectActions) => {
    let selectedRowsId = [];
    selectedRowsId.push(rowData)
    setSelectedRows(selectedRowsId);
    setConfirmType("Delete");
    deleteConfirmAction(rowData, selectActions)
  }

  // conformation for delete item
  const deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      setSelectedRowsId(selectedRowsId);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
    if (rowData) {
      setDeleteRowDataId(rowData);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
  }

  //change dropdown elememt
  /**not using For Now */
  const changeDropDownElement = (event) => {
    dt.filter(event.target.value, event.target.name, 'equals');
    //************ Check for solution
    // setState({
    //   [event.target.name]: event.value
    // });

  }


  const getTableFieldItem = (field) => {
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
      //condition to match fieldType
      if (tablefieldsToShow[i].fieldType == field) {
        return tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  const getColorFromOptions = (options, name) => {
    if (options) {
      if (name && name === 'Super Admin') {
        return 'danger';
      }
      for (let i = 0; i < options.length; i++) {
        if (options[i].value == name) {
          return options[i].color;
        }
      }
    } else {
      if ((name === 'Blocked') || (name && name === 'Super Admin') || name === 'offline') {
        return 'danger';
      } else if (name === 'Pending') {
        return 'warning';
      }
    }
    return 'success';
  }
  const getUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  const getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  const getAUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return data[i]
      }
    }
  }

  const onOpenViewModal = async (rowData, type, from) => {
    let rowDataIndex = getUserData(rowData['_id'], '_id');
    if (from == "route") { rowData = getAUserData(rowData['_id'], '_id') }
    let _id = rowData['_id'];
    let status = rowData['status'] ? rowData['status'] : ''
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await self.getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField],
                    fieldType: fieldType
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key],
                    fieldType: fieldType,
                    options: options
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
            /**not using For Now */
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }
      if (displayViewOfForm === 'modal') {
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        await formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      } else if (displayViewOfForm === 'screen') {
        let displayBreadCrumbField = getTableFieldItem('Link');
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        setDisplayBreadCrumbField(displayBreadCrumbField);
        setDisplayBreadCrumbValue(rowData[displayBreadCrumbField])
      }
    }
    LocationsCartView
  }

  const closeViewModal = async () => {
    setOpenViewModal(false);
  }
  //on changing pagination
  const onPageChange = async (event) => {
    let filter = { ...filterCriteria };
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filter['limit'] = event.rows;
      filter['page'] = currentPage;
      setCurrentPage(currentPage);
      setRows(event.rows);
      setFirst(event.first);
      setPage(event.first);
    }
    setFilterCriteria(filter)
    if (props.type === 'Orders') {
      getDataFromServer(filter, 'search')
    } else if (props.type === 'Storage Units') {
      getDataFromServer(filter, props.tabType)
    } else {
      getDataFromServer(filter)
    }
  }
  /**not using For Now */
  const onPageChangeAccordion = async (event) => {
    let filterCriteria = filterCriteria;
    let id = openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
    }
    // getDataFromServer(filterCriteria)
  }
  //sorting fields
  const sortChange = (event) => {
    // setState({ selectedRows: '' });
    setSelectedRows('');
    let sort = sortCount;
    if (event && event['sortField']) {
      sort = sort == 0 ? sort + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = {
        direction: sort == 0 ? "desc" : 'asc',
        sortfield: sortField,
        limit: rows, page: currentPage,
      }
      setIsLoading(true)
      setSortCount(sort);
      setFilterCriteria(filterCriteria);
      if (props.type === 'Orders') {
        getDataFromServer(filterCriteria, 'search');
      } else {
        getDataFromServer(filterCriteria);

      }
    }
  }
  const confirmActionType = async (type) => {
    if (type === 'Delete') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Delete')
      deleteConfirmAction()
    } else if (type === 'Block') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block')
      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block');
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
      // deleteConfirmAction()
    } else if (type === 'Send Email') {
      setOpenSendEmailModal(true);
      // deleteConfirmAction()
    }
  }

  //onActionsChange
  const onActionsChange = async (event, type) => {
    if (type == 'dropdownFilter') {
      for (let i in actionsTypes) {
        if (actionsTypes[i].value === event.target.value) {
          if (actionsTypes[i].action) {
            actionsTypes[i].action(selectedRows, actionsTypes[i].field, actionsTypes[i].value)
            return;
          }
        }
      }
      confirmActionType(event.target.value)
    }
  }


  //ENTIRE CART
  const entireCartFilterValues = async (e) => {
    if (e) {
      let filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' }
      await setStorageUnitSubLocations(e.value)
      if (e.value === "ENTIRE CART") {
        filterCriteria["criteria"] = [];
      } else {
        filterCriteria["criteria"].push({
          "key": "storageUnitSubLocation",
          "value": `${e.value}`,
          "type": "eq"
        })
      }
      await setFilterCriteria(filterCriteria);
      await getDataFromServer(filterCriteria, categoryType);
      setIsLoading(false);
    }
  }

  // Category Filter Value 
  const categoryFilterValues = async (e) => {
    if (e) {
      await setCategoryType(e.value)
    }
    // let criteria = filterCriteria
    let criteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' }
    setFilterCriteria(criteria)
    // setCurrentPage(1);
    setRows(20);
    setFirst(0);
    setPage(1);
    await getDataFromServer(criteria, e.value)
    setIsLoading(false)
  }

  const rowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };

  const selectFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear style={{ minWidth: '12rem' }} />
    );
  };


  const onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][index] && filterCriteria['criteria'][index]["value"]) {
        v = [...filterCriteria['criteria'][index]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][index]["value"] = v;
      } else {
        v[0] = value
        filterCriteria['criteria'].push({
          key: key,
          value: v,
          type: 'in'
        });
      }
      setFilterCriteria(filterCriteria)
      await getDataFromServer(filterCriteria)
    } else {
      getDataFromServer(filterCriteria)
    }
  }


  const onFilterColumns = (event) => {
    let filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' }
    if (event && event.filters) {
      let filters = { ...event.filters };
      for (const field in filters) {
        if (filters[field] && filters[field].value) {
          if (fieldMap[field] === 'date') {
            let dateFilters = formatDateFilters(filters[field], field);
            dateFilters.forEach((dateFilter) => {
              filterCriteria.criteria.push(dateFilter);
            });
          } else if (fieldMap[field] === 'dropdown') {
            if (filters[field].value.length > 0) {
              filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'in' });
            }
          }
          else if (fieldMap[field] === 'radio') {
            if (filters[field].value.length > 0) {
              filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'in' });
            }
          }
          else if (fieldMap[field] === 'number') {
            if (filters[field].value.length > 0) {
              filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'eq' });
            }
          }
          else {
            filterCriteria.criteria.push({ key: field, value: filters[field].value, type: 'regexOr' });
          }
        }
      }
    }
    setFilterCriteria(filterCriteria);

    getDataFromServer(filterCriteria);
  }

  const getOneDayQuery = (date) => {
    let todayDate = moment(new Date(date)).format('YYYY-MM-DD');
    return { $lte: new Date(todayDate + 'T23:59:59Z'), $gte: new Date(todayDate + 'T00:00:00Z') };
  }
  const getPreviousDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() - 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };
  const getNextDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() + 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

  const formatDateFilters = (filterObj, field) => {
    if (filterObj.value && filterObj.value.length === 2) {
      if (filterObj.value[1] !== null) {
        const start = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const initialDate = getPreviousDate(start);
        const inputDateForNext = dateFormats.formatDate(filterObj.value[1], config.dayYearDateFormat);

        const nextDate = getNextDate(inputDateForNext);

        return [
          { key: field, value: initialDate, type: 'gte' },
          { key: field, value: nextDate, type: 'lt' }
        ];
      } else {

        const inputDate = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);

        const previousDate = getPreviousDate(inputDate);

        let date = getOneDayQuery(previousDate);
        return [{ key: field, value: previousDate, type: 'gte' }, { key: field, value: inputDate, type: 'lte' }]

      }
    } else {
      return [];
    }
  }




  // on search get data from server
  const onFilterChange = async (event, type) => {
    setIsLoading(true);
    setSelectedRows('');
    if (type === 'dropdownFilter' || type === 'date') {

    }
    let fieldName = '', filter = { ...filterCriteria },
      selectedFilterValue, selectedFilter, selecterFilterType, formattedTime, formattedDate, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (fieldName == 'date' || fieldName == 'created' || fieldName == 'updated') {
          isDateFilter = true;
          selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
            selectedFilter.value : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dayYearDateFormat);
          selecterFilterType = 'eq'
        } else {
          selecterFilterType = 'regexOr'
          selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
        setGlobalSearch(selectedFilterValue);
      }
      if (type == 'dropdownFilter') {
        selecterFilterType = 'in'
      }
      if ((type == 'dropdownFilter' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) || (type !== 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        if (fieldName == 'createdBy') {
          fieldName = 'createdBy.name';
        }
        if (fieldName == 'phone') {
          fieldName = 'phone';
        }
        if (fieldName == 'globalSearch') {
          filter.globalSearch = {
            value: selectedFilterValue,
            type: 'user'
          }
          if (event.target.value.length == 0) {
            delete filter.globalSearch;
          }
        } else {
          if (selecterFilterType == 'gte') {
            let obj = filter.criteria.find(x => x.key == fieldName);
            let index = filter.criteria.indexOf(obj);
            if (index != -1) {
              filter['criteria'].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            } else {
              filter['criteria'].push({
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            }
          } else {
            if (fieldName == 'updated') {
              fieldName = 'updated.name'
            }
            if (filter['criteria'].length == 0 && selecterFilterType != 'lte') {
              filter['criteria'].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType
              });
            } else {
              let obj = filter.criteria.find(x => x.key == fieldName);
              let index = filter.criteria.indexOf(obj);
              if (selecterFilterType == 'lte') {
                if (selectedFilterValue.length == 10) {
                  filter['criteria'].splice(0, 1, ({
                    key: fieldName,
                    value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(3, 5) + '-' + selectedFilterValue.substring(0, 2) + 'T23:59:59Z',
                    type: selecterFilterType
                  }));
                }
              }
              if (index != -1 && selecterFilterType != 'lte') {
                filter['criteria'].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              } else if (selecterFilterType != 'lte') {
                filter['criteria'].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              }
            }
          }

          setFilterCriteria(filter);
        }
        if (props.type === 'Orders') {
          await getDataFromServer(filter, 'search')

        } else {
          await getDataFromServer(filter, type)

        }
      } else if (type === 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) {
        if (selectedFilterValue.length == 2) {
          let fieldIndex1 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          let fieldIndex2 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          if (fieldIndex1 >= 0) filter.criteria.splice(fieldIndex1, 1)
          if (fieldIndex2 >= 0) filter.criteria.splice(fieldIndex2, 1)
          let startDate = (moment(selectedFilterValue[0]).format('YYYY-MM-DD'));
          let eDate = selectedFilterValue[1] ? JSON.parse(JSON.stringify(selectedFilterValue[1])) : JSON.parse(JSON.stringify(selectedFilterValue[0]))
          eDate = new Date(eDate)
          let endDate = eDate.setDate(eDate.getDate() + 1);
          endDate = (moment(endDate).format('YYYY-MM-DD'));
          filter['limit'] = 20;
          filter['page'] = 1;
          filter.criteria.push({ 'key': fieldName, 'value': startDate, 'type': 'gte' })
          filter.criteria.push({ 'key': fieldName, 'value': endDate, 'type': 'lte' })
        }

        setFilterCriteria(filter);
        await getDataFromServer(filter)

      } else {
        if ((selectedFilterValue == null || (type === 'dropdownFilter' && selectedFilterValue.length == 0)) && !isDateFilter) {
          let obj = filter.criteria.find(x => x.key == fieldName);
          let index = filter.criteria.indexOf(obj);
          filter.criteria.splice(index, 1);
          if (fieldName == 'globalSearch') {
            filter.globalSearch = {}
            delete filter.globalSearch
          }

          setFilterCriteria(filter);
          if (props.type === 'Orders') {
            await getDataFromServer(filter, 'search')

          } else {
            await getDataFromServer(filter, type)

          }
          // await getDataFromServer(filter)
        }
      }

      setIsLoading(false);
    }
  }

  //open Bulk Modal
  const bulkUploadMoadal = () => {
    setOpenBulkUploadModal(true);
  }

  //close Bulk Modal
  const closeBulkModal = async () => {
    setOpenBulkUploadModal(false);
  }

  //select multiple rows to delete
  const onSelectRowsUpdate = async (event) => {
    if (props.type === 'MissingItems') {
      await setOrdersData(event.value)
    }
    setSelectedRows(event.value);
    await getActions()
  }



  //openConfirmationModal
  const openConfirmationModal = async (rowData, status, type) => {
    setIsOpenFormModal(false);
    setOpenConfirmationModal(true);
    setItem(rowData);
    setConfirmText(type);
    setLeaveStatus(status);
  }

  const closeConfirmationModal = () => {

    setOpenConfirmationModal(false);
  }
  const setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )
    setFilterCriteria(filterCriteria);
  }
  const getTooltipFromOtptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }

  const getArrayBadges = (badge_values) => {
    let str = [...badge_values].join(',');
    return str;
  }

  const ResendEmail = (item) => {
    let userBody = item;
    return fetchMethodRequest("POST", "orders/resendEmail", userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
      })
      .catch((err) => {
        return err;
      });
  };


  //change table body values//
  // hari need to move to derived class or controller
  const changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = getTableFieldItem(column.field);

    if (tableItem.fieldType == 'fileUpload') {
      if (tableItem.fileType === 'img/video') {
        return (
          <div>
            {item && item[column.field] && item[column.field].length > 0 ? (
              <div>
                <FileGalleryView
                  item={item}
                  column={column}
                  type={props.type}
                />
              </div>
            ) : null}
          </div>
        );
      } else {       
          return (
            <div className='textElipses'>
              {item[column.field]?.length > 0 && item[column.field].map((img, index) => {
                return (
                  <a key={index} href={`${config.imgUrl}${props.type.toLowerCase()}/${img}`} target='_blank'>
                    <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]} alt="" />
                  </a>
                );
              })
              }
            </div>
          );
      }
    }
    
    // Admin Data 
    // if( (loginRoleType === "Super Admin" || props.name === "Active Companies" )&& tableItem.field === "name"){
    if (props.name === "Active Companies" && tableItem.field === "companyName") {
      return <div className='textElipsess'>
        <span title={item[column.field]}>
          <NavLink to={`/${props.redirectTo}?comapnyId=${item._id}&companyName=${item.name}`} >
            <span style={{ fontWeight: "bold" }}>{item[column.field]}</span>
          </NavLink>
        </span>
      </div >
    }

    // front screen onclick fieldtype

    if (tabType === 'Placed Orders' && column.field === "reSendEmail") {
      return (
        <button
          className="btn custom-outline"
          color="primary"
          onClick={() => ResendEmail(item)}
          style={{ display: 'inline-block', marginRight: '10px' }}
        >
          Re-Send Email
        </button>
      );
    }

    if (tableItem.fieldType == "getRedirect") {

      if (!localStorage.getItem('Inventory CheckLists Count')) {
        localStorage.setItem('Inventory CheckLists Count', JSON.stringify({
          storageUnitId: item.storageUnitId,
          facilityName: item.facilityName,
          facilityId: item.facilityId,
          storageUnitName: item[column.field],
          tabType: props && props.tabType
        }))
      }
      return <div className='textElipses'>
        <span title={item[column.field]}>

          <NavLink to={`/${props.redirectTo}?storageUnitId=${props.type == "Storage Units"
            ? item._id
            : item.storageUnitId}&facilityName=${item.facilityName}&storageUnitName=${item.storageUnitName}&facilityId=${item.facilityId}&tabType=${props && props.tabType}&comapanyId=${props.companyId}&companyName=${props.companyName ? props.companyName : null}`} >
            {/* &facilityName=${item.facilityName}&storageUnitName=${item.storageUnitName}&tabType=${props&&props.tabType} */}

            <span style={{ fontWeight: "bold" }}>{item[column.field]}</span>
          </NavLink>
        </span>
      </div >
    }
    if (column.field == "isOrdered") {
      if (item[column.field]) {
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>Yes</span>
          </span>
        );
      } else {
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>No</span>
          </span>
        );
      }
    }


    if (column && column.field) {
      if (column.field === 'created' || column.field === 'trialPeriod' || column.field === 'validateTo' || column.field === 'validateFrom' || column.field === 'updated') {
        const date = new Date(item[column.field]);
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')
          }-${date.getFullYear()}`;


        return (
          <span>
            {item[column.field] ? formattedDate : null}
          </span>
        );
      }
    }




    if (props.type === 'Recommended Inventories' && tableItem.fieldType === 'Link' && column.field === 'minInventoryByStateName') {
      return (
        <span onClick={() => OpenRecommendedInventoryModal(item)} style={{ color: '#3498db', fontWeight: 'bold', cursor: 'pointer' }}>
          {item[column.field]}
        </span>
      );
    }



    if (tableItem.fieldType === "hyphen") {
      return (
        <div className={`d-flex justify-content-${tableItem.textAlign && tableItem.textAlign.toLowerCase() === "center" ? 'center' : 'start'}`}>
          <div data-toggle="tool-tip" title={item[column.field]} className='toolTipModify' style={{ ...tableItem.style, maxWidth: tableItem.width, }}>
            {item[column.field] ? item[column.field] : '-'}
          </div>
        </div>
      );
    }
    // if (tableItem.fieldType == "Link") {
    //   return <div className='textElipses'>
    //     <span
    //       /**not using For Now */
    //       // onClick={() => openViewModal = true}
    //       title={item[column.field]}>
    //       <a href={`/view_${props.routeTo}/${JSON.stringify(item._id)}`} >  {item[column.field]}</a>
    //     </span>
    //   </div >

    // }
    if (tableItem.fieldType == "Link") {
      return <div className='textElipses'>
        <div onClick={() => openFormModal(item, 'view',dirty)} style={tableItem.style} title={item[column.field]}>
          {item[column.field]}
        </div >
      </div >
    }
    if (tableItem.isClickable && tableItem.isClickable == true) {
      return <div className='textElipses'>
        <span
          /**not using For Now */
          // onClick={() => openViewModal(item, 'view')}
          title={item[column.field]}>
          <a href={`/view_${props.routeTo}/${JSON.stringify(item._id)}`} >  {item[column.field]}</a>
        </span>
      </div >
    }
    else if (tableItem.type === "time") {

    }
    // Upload icons  
    if (tableItem.field == "isCopyExistingRec") {
      return (
        <span>
          <FontAwesomeIcon
            className="genderIconAlignment mr-3"
            color="white"
            icon="plus"
            data-toggle="tool-tip"
            title="Add"
            style={{ color: "#024a88", width: "15", cursor: "pointer", marginRight: 30 }}
            /**not using For Now */
            onClick={() => openFormModal(item, "edit")}

          />
          <FontAwesomeIcon
            className='genderIconAlignment mr-2'
            color='white'
            icon='trash-alt'
            title="Delete"
            style={{ color: '#bf1725', width: '13', marginRight: 15, cursor: 'pointer' }}
            /**not using For Now */

            // onClick={() => deleteQuantites(item, "Delete")} 
            onClick={() => setDeleteRecords(item, "Delete")}
          />
          {props.type !== "Inventory Check Lists" ? <FontAwesomeIcon
            icon='upload'
            className='genderIconAlignment mr-2'
            data-toggle="tool-tip"
            title="upload"
            style={{ color: '#024a88', width: '13', marginLeft: 20, cursor: 'pointer' }}
            onClick={() => { openStorageUnitItemsModal(item) }}
          /> : null}
        </span>
      );
    }
    if (tableItem.fieldType === "addToOrderList") {
      return (
        <Button
          className="add_to_orders_btn btn-btn-outline"
          label={
            <>
              <span style={{ fontSize: 12, fontWeight: 'bold' }}>
                Add&nbsp;To
                <br />
              </span>
              <span style={{ fontSize: 12, fontWeight: 'bold' }}>
                {" "}
                Orders&nbsp;List
              </span>
            </>
          }
          severity={item.isOrdered ? "danger" : "primary"}
          onClick={() => {
            getOrderConfirmationModal(item)
            // props.addToOrderList(item); 
            // openFormModal(item, "edit", true)
            // setIsOpenFormModal(true); 
          }}
          outlined
        />
      )
    }


    if (tableItem.isClickable && tableItem.isClickable == true) {
      return <div className='textElipses'>
        <span
          /**not using For Now */
          // onClick={() => openViewModal(item, 'view')}
          title={item[column.field]}>
          <a href={`/view_${props.routeTo}/${JSON.stringify(item._id)}`} >  {item[column.field]}</a>
        </span>
      </div >
    } else if (tableItem.fieldType == "isObject") {
      let val = item && item.productName
      return (<span>{val}</span>)
    }

    else if (tableItem.fieldType == "NeedToOrder") {
      let val = item.limit != null && item.count != null
        ?
        item.limit - item.count >= 0 ? (item.limit - item.count) : 0
        :
        null
      return (<span>{val}</span>)
    } else if (tableItem.fieldType === "currentCount") {
      let val = item.count + item.fourWeeksCount
      return (<span>{val}</span>)
    }
    else if (tableItem.field == "limit") {
      let val = item[column.field]
      return (<span>{val}</span>)
    }
    else if (tableItem.fieldType === "isCountZero") {
      let val = item[column.field]
      if (val || val === 0) {
        return (<span>{val}</span>)
      } else {
        return (<span>-</span>)

      }
    } else if (tableItem.fieldType === "valuePresent") {
      let val = item[column.field]
      if (val) {
        return (<span>{val}</span>)
      } else {
        return (<span>-</span>)

      }
    } else if (tableItem.fieldType === 'ActiveStatus') {
      return (
        <span>Active</span>
      )
    }
    else if (tableItem.fieldType === "jsonObject") {
      let val;
      if (item.requestJson && item.requestJson.json && item.requestJson.json.body) {
        val = item.requestJson.json.body[column.field]
      }
      return (
        <div>{val}</div>
      )
    }
    else if (tableItem.type === "time") {
      const timestamp = item[column.field];
      if (timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        let period = 'AM';

        if (hours >= 12) {
          period = 'PM';

          // Convert 12-hour format to 12 AM/PM format
          if (hours > 12) {
            hours -= 12;
          }
        }
        // Format the time as "hh:mm AM/PM"
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
        return <span style={tableItem.style} title={formattedTime}>
          {formattedTime}
        </span>;
      }
    }
    else if (tableItem.fieldType == "WebSite") {
      return <div className='textElipses'>
        <a href={item[column.field]} title={item[column.field]} target="_blank">{item[column.field]}</a>
      </div >
    } else if (tableItem.fieldType == "Download") {
      return <div className='textElipses'>
        <a href={item["duplicateFileDownloadUrl"]} title={item[column.field]} target="_blank">{item[column.field]}</a>
      </div >
    }
    /**not using For Now */
    // else if (tableItem.fieldType == "dropDown") {
    //   let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
    //   return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
    // }
    // else if (!tableItem.isMultiSelect && tableItem.fieldType == "dropDown") {
    //   let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
    //   return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
    // }
    else if (tableItem.isMultiSelect && tableItem.fieldType == "dropDown") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} pill style={tableItem.style}> {getArrayBadges(item[column.field])}</Badge>
    }
    else if (tableItem.fieldType == "Badge") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
    } else if (tableItem.fieldType == "Role") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
    } else if (tableItem.fieldType === "icon") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      let tooltip = getTooltipFromOtptions(tableItem.options, item[column.field]);
      return <FontAwesomeIcon
        style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
        color='white'
        icon={tableItem.iconName}
        data-toggle="tool-tip"
        title={tooltip}
      />
    } else if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else if (tableItem.fieldType === "Complex") {
      let data = ObjectbyString(item, column.field)
      return <span>
        {data}
      </span>
    }
    else if (tableItem.fieldType == "Actions") {
      let arr = tableItem.customFun ? tableItem.customFun(item) : ['edit', 'delete', 'preview']
      return (
        <div className='row'
          style={{ justifyContent: 'center' }}>
          <div>
            {(props.preview && arr.includes('preview')) ? <FontAwesomeIcon
              className='genderIconAlignment'
              color='#17a2b8'
              icon='file'
              data-toggle="tool-tip"
              title='Preview'
              style={{ color: '#17a2b8', width: '15', cursor: 'pointer', marginRight: 9 }}
              onClick={() => openPreviewModal(item)} /> : ''}
            {props.editRequired && arr.includes('edit') && tabType !== 'Fulfilled Orders' ?
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='edit'
                data-toggle="tool-tip" title="Edit"
                style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
                onClick={() => openFormModal(item, 'edit')} />
              : ''}
            {(props.deleteRequired && arr.includes('delete')) && tabType !== 'Placed Orders' && (props.type !== "Products" || (props.type == "Products" && item.isCompanyProduct)) ?
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='trash-alt'
                data-toggle="tool-tip"
                title="Delete"
                style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                onClick={() => setDeleteRecords(item, "Delete")} />
              : null}
          </div>
          {/* } */}
        </div >
      )
    } else if (tableItem.fieldType == "relateAutoComplete") {
      if (tableItem.isMultiple) {
        let data = ""
        if (tableItem.searchField && item[column.field] && item[column.field].length > 0) {
          for (let obj of item[column.field]) {
            data = obj[tableItem.searchField] + "," + data
          }
        }
        return <span style={tableItem.style} title={data} >
          {data}
        </span >
      } else {
        return tableItem.searchField && item[column.field] && item[column.field][tableItem.searchField] ?
          <span style={tableItem.style} title={item[column.field][tableItem.searchField]} >
            {item[column.field][tableItem.searchField]}
          </span >
          : null;
      }

    } else if (tableItem.fieldType == "RACSubField") {
      return tableItem.parentField && item[tableItem.parentField] && item[tableItem.parentField][tableItem.subFieldName] ?
        <span style={tableItem.style} title={item[tableItem.parentField][tableItem.subFieldName]} >
          {item[tableItem.parentField][tableItem.subFieldName]}
        </span >
        : null;
    } else {
      /**not using For Now */
      // if (item[column.field] === 0) {
      //   return item[column.field];
      // }
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return (
          <div className={`d-flex justify-content-${tableItem.textAlign && tableItem.textAlign.toLowerCase() === "center" ? 'center' : 'start'}`}>
            <div style={{ ...tableItem.style, maxWidth: tableItem.width, whiteSpace: "nowrap" }} title={item[column.field]}>
              {item[column.field]}
            </div>
          </div>
        )
      }
    }
  }
  const clickConfirm = () => {
    closeConfirmationModal();
  }
  const ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  const rowClassName = (item) => {
    if (item.status === 'Blocked') {
      return (
        { 'p-highlightRow': (item.status === 'Blocked') }
      )
    }
  }
  const openPreviewModal = async (item) => {

    setIsPreviewModal(true);
    setEmailTemplateData(item ? item : item);
  }
  const closePreviewModal = () => {

    setIsPreviewModal(false);
    setEmailTemplateData('');
  }
  const closeSendEmailModal = () => {

    setOpenSendEmailModal(false);
    setActions('');
  }
  const handleDateInterval = (startDate, endDate) => {
    if (startDate) {

      setStartDate(startDate);
    }
    if (endDate) {

      setEndDate(endDate);
    }
  }
  // ShowHideColumns Button Click Handler
  //Settings Modal open
  const openShowHideColumnsModal = () => {

    setIsOpenShowHideColumnsModal(true);
  }
  const getSettings = async () => {
    props.getSettings();
  }

  //Handle Tab Type Function 
  let getSelectedTabTypeInfo = async (tabType) => {
    let filterCriteria = undefined;
    let tableFields = await props.getTableFields(tabType);
    // let formFields = await props.getFormFields(tabType);
    await setTabType(tabType);
    await setTablefieldsToShow(tableFields);
    // await setFormFields(formFields)
    // await setOriginalTableFields(tableFields)
    await getDataFromServer(filterCriteria, tabType);

  };

  const makeWishlist = () => {
    setIsOpenWishlist(true);
  }


  const closeRecommendedInventoryModal = () => {
    setOpenRecommendeInventoriesModal(false)
  };
  // // Category Dropdown function
  // let categoryFilterValues = async (e) => {
  //   if (e) {
  //     setIsCategory(true);
  //     await setCategoryType(e.value);
  //   }
  //   let criteria = filterCriteria;
  //   // getDataFromServer(criteria , e.value)

  // }

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  const closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      // setState({
      //   isOpenShowHideColumnsModal: false,
      // })
      setIsOpenShowHideColumnsModal(false);
      if (viewType === 'grid') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.mobile = value;
            }
          });
        })
      }
      if (viewType === 'list') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.show = value;
            }
          });
        })
      }
      fields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });

      localStorage.setItem(`${props.type}_column_order`, JSON.stringify(updatedOrder));
      await getTableFieldsOrder(originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];

      setIsOpenShowHideColumnsModal(false);
      localStorage.setItem(`${props.type}_column_order`, JSON.stringify(fields));

      await getTableFieldsOrder(originalTableFields);
    } else {

      setIsOpenShowHideColumnsModal(false);
    }
  }
  const searchInDateRange = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      setFilterCriteria(filterCriteria);
      getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }

  // Handle when click on refresh icon
  const refresh = () => {
    getDataFromServer(this.state.filterCriteria, "refresh");
  };
  //close Wishlist Modal
  const closeWishlistModal = () => {
    setIsOpenWishlist(false);
  }

  const closeAddQuantitesModal = () => {
    setisOpenQuantitesModal(false)
  }

  const closeCategoryModal = () => {
    setOpenCategoryModal(false)
  }

  const closeStorageUnitModal = () => {
    setOpenStorageUnitModal(false)
  }


  const toggle = async (id) => {
    let opId = openId === id ? '' : id;
    setOpenId(opId);
  }

  const print = () => {
    window.print();
  }
  const toogleMultiSelect = async () => {

    setShowMultiSelectDropDown(!showMultiSelectDropDown);
  }
  const onGlobalSearchChange = async (e) => {
    let suggestions = suggestions;
    suggestions[0] = { "label": `Search By First Name ${e.query}` }
    setSuggestions(suggestions);
  }
  const getScreenHeader = () => {
    const { t } = props;
    return (
      /**not using For Now */
      // <div className="row">
      //   {/* global Search */}
      //   <div className='col-6'>
      //   </div>
      //   <div className='col-6'>
      //     <div className='serachAlignment float-right' >
      //       <div className="p-inputgroup">
      //         <span className="p-inputgroup-addon">
      //           <i className="pi pi-search" ></i>
      //         </span>
      //         <InputText
      //           type="search"
      //           name='globalSearch'
      //           onChange={(e) => onFilterChange(e)}
      //           placeholder={props.globalSearch ? props.globalSearch : 'search'}
      //           size="30" />
      //       </div>
      //     </div>
      //   </div>
      // </div >

      <div className='pb-2'>
        <div className='row '>
          <div className='col-6 mt-1'>
            <h4>
              <span className='postionRelative pt-2 blue_Link'>
                <b>
                  {props.type === "Min Inventory By Products" || props.type === "Product Quantities" || props.type === "Inventory Check Lists" || props.type === "MissingItems"
                    || props.type === 'Min Inventory By States' || props.type === 'Products' || props.type === 'Distributors' ? (
                    <div style={{ color: '#3475aa' }}>{props.headerName}</div>
                  ) : (
                    <div style={{ color: '#3475aa' }}>{t(props.type)}
                      {props.addRequired && !isOpenFormModal && props.type === 'Storage Units' &&
                        <Button
                          size="sm"
                          className="p-2 ml-auto mt-1 mb-1"
                          onClick={() => openFormModal({}, 'add')}
                          style={{ verticalAlign: 'middle', marginLeft: '0.5rem' }}
                        >
                          <FontAwesomeIcon
                            icon='plus'
                            className='pl-1' size='lg'
                            data-toggle="tool-tip" title={t("Add")}
                          />
                        </Button>
                      }
                    </div>
                  )}
                </b>
                {isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
              </span>
            </h4>
          </div>
          <div className='col-6'>
            {
              // !isOpenFormModal && 
              props.globalSearch ?
                <div className='doInput serachAlignment float-right' >
                  <div className="p-inputgroup w-50">
                    <span className="p-inputgroup-addon">
                      < i className="pi pi-search" ></i >
                    </span>
                    <InputText
                      type="search"
                      name='globalSearch'
                      id='globalSearch'
                      value={globalSearch}
                      onChange={(e) => onFilterChange(e)}
                      placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                      size="30" />
                  </div>
                </div>
                : null
            }

          </div>
        </div>
        {/* Header Titles */}
        {["Inventory Check Lists", "MissingItems", "Expired Items", "Product Quantities"].includes(props.type) ?
          <div className={props.type === "Inventory Check Lists" ? "mt-4" : 'headerInfostyle'}>
            <div className="m-1">
              <div>
                <span
                  style={{
                    color: "#3475aa",
                    fontWeight: 550,
                  }}
                >
                  FACILITY
                </span>{" "}
                : <span style={{ textTransform: "uppercase" }}>{props.facilityName ? props.facilityName : ""}</span>
              </div>
              <span
                style={{
                  color: "#3475aa",
                  fontWeight: 550, textTransform: "uppercase",
                }}
              >
                CART NAME
              </span>{" "}
              : <span style={{ textTransform: "uppercase" }}>{props.storageUnitName ? props.storageUnitName : ""}</span>
              <br />

              {loginCredentials.role === "Super Admin" && <div>
                <span
                  style={{
                    color: "#3475aa",
                    fontWeight: 550, textTransform: "uppercase",
                  }}
                >
                  COMPANY NAME
                </span>{" "}
                : <span style={{ textTransform: "uppercase" }}>{props.companyName ? props.companyName : ""}</span>
                <br />
              </div>}
            </div>
          </div>
          :
          null
        }
        <div className="row">
          {/* global Search */}
          <div className={(props.dateSearchRequired) ? "col-9" : 'col-6'}>
            {(props.dateSearchRequired) ?
              <div className='row'>
                <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                </RenderIntervalDatePickerField>
                <Button className="col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
              </div>
              : null}
          </div>

          {/* {
            // !isOpenFormModal && 
            props.globalSearch ?
              <div className={(props.dateSearchRequired) ? "col-3" : 'col-6'}>
                <div className='serachAlignment float-right' >
                  <div className="p-inputgroup w-50">
                    <span className="p-inputgroup-addon">
                      < i className="pi pi-search" ></i >
                    </span>
                    <InputText
                      type="search"
                      name='globalSearch'
                      id='globalSearch'
                      value={globalSearch}
                      onChange={(e) => onFilterChange(e)}
                      placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                      size="25" />
                  </div>
                </div>
              </div> : null
          } */}
        </div >
        <div className='row mt-3'>
          {/* global Search */}
          <div className='col-sm-12'>
          </div>

          <div className='col-md-7 d-flex' style={{ height: '80%' }}>

            {props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
              <span className='multiSelectDropDownCard '>
                <span onClick={toogleMultiSelect}>
                  {t('Filters')}
                </span>
                <span onClick={toogleMultiSelect}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className='ml-1'
                    color='grey'
                  />
                </span>
                {
                  showMultiSelectDropDown ?
                    <Card className=' itemsWarperCard' >
                      {
                        tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                          return (
                            item.displayInFilter && item.show && < div >
                              <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                              <ul className='pl-0'>
                                {
                                  item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                    return (
                                      <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                        <span className='chekcIconAdjust'>
                                          <Checkbox
                                            // onChange={e => setChecked(e.checked)}
                                            checked={optionsItem.checkIcon}>
                                          </Checkbox>
                                          {/* <FontAwesomeIcon
                                                icon='check'
                                                color={optionsItem.checkIcon ? 'grey' : 'white'}
                                              /> */}
                                        </span>
                                        <span className='chekcIconAdjustLabel'>
                                          {t(optionsItem.label)}
                                        </span>
                                      </div>
                                    )
                                  }) : null
                                }
                              </ul>
                              <hr className='my-0'></hr>
                            </div>
                          )
                        }) : null
                      }
                    </Card>
                    : null}
              </span>

            </span> : null}
            { /*not using For Now */}
            {/* {!isOpenFormModal ? <MultiSelect
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={state['multiActions'] ? state['multiActions'] : null}
                  options={props.actionsTypes}
                  onChange={(e) => onFilterChange(e, 'dropdownFilter')}
                /> : null} */}
            {!isOpenFormModal && actionsTypes && actionsTypes.length > 0 ?
              <Dropdown
                className='mr-3 actions_dropdown'
                // appendTo={document.body}
                name='actions'
                value={actions}
                disabled={selectedRows && selectedRows.length > 0 ? false : true}
                options={actionsTypes}
                placeholder={t('Actions')}
                onChange={(e) => onActionsChange(e, 'dropdownFilter')}
              /> : null}

            {/* cartSubLocation DropDown */}
            {/* <div className='mr-3'> */}
            {["Inventory Check Lists"].includes(props.type) && !isOpenFormModal ?
              <Dropdown
                className='mr-3 categoryType_dropdown'
                appendTo={document.body}
                name={"cartSubLocation"}
                value={storageUnitSubLocations}
                options={entireCartOptions}
                placeholder='ENTIRE CART'
                onChange={(e) => entireCartFilterValues(e, 'storageUnitSubLocations')}
              /> : null}
            {/* </div> */}
            {/* Categorys DropDown */}
            {["Products", "Expired Items", "MissingItems", "Product Quantities", "Inventory Check Lists", "Min Inventory By Products"].includes(props.type) && !isOpenFormModal ?
              <Dropdown
                className='mr-3 categoryType_dropdown'
                appendTo={document.body}
                name={"categoryType"}
                value={categoryType}
                options={[{ label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }]}
                // style={{height:"40px" , width:"180px"}}
                onChange={(e) => categoryFilterValues(e, 'dropdownFilter')}
              /> : null}
            {["Orders"].includes(props.type) && !isOpenFormModal ?
              < Dropdown
                className='mr-3 categoryType_dropdowns'
                appendTo={document.body}
                name={"categoryType"}
                value={categoryType}
                options={[{ label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }]}
                // style={{ height: "40px", width: "180px" }}
                onChange={(e) => categoryFilterValues(e, 'dropdownFilter')}
              /> : null}
            {props.type === 'MissingItems' ? (
              <span>
                <Button
                  className='mr-3 categoryType_dropdown'
                  color="primary"
                  onClick={() => getMultiOrdersData()}
                  disabled={!selectedRows.length}
                >
                  Add To Orders List
                </Button>
              </span>
            ) : null}


            <div className='ps-2 flex flex-wrap justify-content-center gap-2'>
              {!isOpenFormModal && props.exportRequired &&
                <CSVLink
                  data={exportData}
                  filename={`${props.type}.csv`}
                  className="hidden text-white"
                  ref={csvLinkRef}
                  target="_blank" >
                </CSVLink>}
              {!isOpenFormModal && props.sample ?
                <Button
                  size="small"
                  onClick={() => bulkUploadMoadal()}>
                  <FontAwesomeIcon
                    icon='upload'
                    size='lg'
                    data-toggle="tool-tip" title={t("Bulk Upload")}
                    onClick={() => bulkUploadMoadal()} />
                </Button>
                : null}

              {!isOpenFormModal && props.gridRequried ?
                <div class="btn-group mb-0" role="group">
                  <Button
                    outlined={viewType === 'grid' ? false : true}
                    size="small"
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    onClick={() => changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      size="small"
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => changeViewType('grid')}
                    />
                  </Button>
                  <Button
                    size="small"
                    outlined={viewType === 'list' ? false : true}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    onClick={() => changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => changeViewType('list')}
                    />
                  </Button>
                </div> : null}

              {/* //settings button */}
              {!isOpenFormModal && props.settingsRequired ?
                <Button
                  size="sm"
                  className="p-2 ml-auto mr-4 mt-1 mb-1"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button> : null}

              {/* //Export to csv button */}
              {!isOpenFormModal && props.exportRequired &&
                <Button
                  size="sm"
                  className="p-2 ml-auto mr-4 mt-1 mb-1"
                  onClick={getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                  />
                </Button>
              }

              {/* Add button2 */}
              {props.addRequired && !isOpenFormModal && tabType !== 'Items Recalled' && props.type !== 'Storage Units' ?
                <Button
                  size="sm"
                  className="p-2 ml-auto mr-4 mt-1 mb-1"
                  onClick={() => openFormModal({}, 'add')}
                >
                  <FontAwesomeIcon
                    icon='plus'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Add")}
                  />
                </Button>
                //  </a>
                : null}
              {/* //Refresh Button */}
              {!isOpenFormModal && props.refresh ?
                <Button
                  color="secondary"
                  size="sm"
                  className="p-2 ml-auto mr-4 mt-1 mb-1"
                  onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button> : null}

              {props.type === 'Orders' && tabType === "Orders List" ? (
                <div className='align-tabtypes'>
                  <Button className="ml-auto my-auto" color="primary" onClick={sendEmail}  >
                    Submit
                  </Button>
                </div>
              ) : null}



              {/* //Print Buton */}
              {!isOpenFormModal && props.printRequried ?

                <Button
                  raised
                  size="sm"
                  className="p-2 ml-auto mr-4 mt-1 mb-1"
                  onClick={() => print()}
                >
                  <FontAwesomeIcon
                    icon='print'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Print")}

                  />
                </Button>
                : null}
              {/* Wishlist icon */}
              {!isOpenFormModal && props.cartRequired ?
                <div className="cartDisplay">
                  <div>
                    <Cart
                      className="cart"
                      onClick={() => { makeWishlist() }}
                      size={40}
                      style={{
                        background: "white",
                        color: "#3475aa",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <Badge
                    className="cart_badge badge badge-warning badge-pill ml-n1"
                    color="warning"
                    pill
                  >
                    <span style={{ color: "black" }}>{
                      cartListCount
                      // props.cartCount
                    }</span>
                  </Badge>
                </div>
                : null}
              {/* {props.type === 'MissingItems' ? (
                <span className='pt-8'>
                  <Button
                    className="ml-auto mb-0"
                    color="primary"
                    onClick={() => getMultiOrdersData()}
                    disabled={!selectedRows.length}
                  >
                    Add To Order List
                  </Button>
                </span>
              ) : null} */}

              &nbsp;&nbsp;
            </div>

          </div >
          <div className='col-md-5'>

            {props.getPagination && config.paginationPosition == 'top' ? getPaginator() : null}
          </div>

          {/* common Tab Types */}
          <div >
            {props.type == "Storage Units" || props.commonTabTypes ? (
              <Row className="pb-0">
                <Col sm={12}>
                  <CommonTabTypes
                    getSelectedStorageUnit={getSelectedTabTypeInfo}
                  />
                </Col>
              </Row>
            ) : null}
          </div>
          <div>
            {props.type == "Recall Items" ? (
              <Row className="pb-1">
                <Col sm={12}>
                  <RecallTabType
                    getSelectedRecallTypeInfo={getSelectedTabTypeInfo}
                  />
                </Col>
              </Row>
            ) : null}
          </div>
          <div>
            {props.type == "Orders" ? (
              <Row className="pb-1">
                <Col sm={12}>
                  <OrderTabTypes
                    getSelectedOrderTypeInfo={getSelectedTabTypeInfo}
                    selectedRows={selectedRows}
                    refresh={refresh}
                    sendEmail={sendEmail}
                    getDataFromServer={getDataFromServer}
                  />
                </Col>
              </Row>
            ) : null}
          </div>

          <div className='col-12  pb-2 mt-4 d-none'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {config.paginationPosition == 'top' ? getPaginator() : null}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
                  <span className='multiSelectDropDownCard '>
                    <span onClick={toogleMultiSelect}>
                      {t('Filters')}
                    </span>
                    <span onClick={toogleMultiSelect}>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className='ml-1'
                        color='grey'
                      />
                    </span>
                    {
                      showMultiSelectDropDown ?
                        <Card className=' itemsWarperCard' >
                          {
                            tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                              return (
                                item.displayInFilter && item.show && < div >
                                  <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                  <ul className='pl-0'>
                                    {
                                      item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                        return (
                                          <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                            <span className='chekcIconAdjust'>
                                              <Checkbox
                                                // onChange={e => setChecked(e.checked)}
                                                checked={optionsItem.checkIcon}>
                                              </Checkbox>
                                              {/* <FontAwesomeIcon
                                                icon='check'
                                                color={optionsItem.checkIcon ? 'grey' : 'white'}
                                              /> */}
                                            </span>
                                            <span className='chekcIconAdjustLabel'>
                                              {t(optionsItem.label)}
                                            </span>
                                          </div>
                                        )
                                      }) : null
                                    }
                                  </ul>
                                  <hr className='my-0'></hr>
                                </div>
                              )
                            }) : null
                          }
                        </Card>
                        : null}
                  </span>

                </span> : null}
                {/*not using For Now */}
                {/* {!isOpenFormModal ? <MultiSelecht
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={state['multiActions'] ? state['multiActions'] : null}
                  options={props.actionsTypes}
                  onChange={(e) => onFilterChange(e, 'dropdownFilter')}
                /> : null} */}

                {!isOpenFormModal && actionsTypes && actionsTypes.length > 0 ? <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={actions}
                  disabled={selectedRows && selectedRows.length > 0 ? false : true}
                  options={actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => onActionsChange(e, 'dropdownFilter')}
                /> : null}
                <div className='flex flex-wrap justify-content-center gap-3'>
                  {!isOpenFormModal && props.printRequried ?

                    <Button
                      raised
                      size="sm"

                      onClick={() => print()}
                    >
                      <FontAwesomeIcon
                        icon='print'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Print")}
                        onClick={() => print()}
                      />
                    </Button>

                    : null}
                  {!isOpenFormModal && props.exportRequired && <Button color="primary"
                    className='p-1 ml-auto mb-0 mt-1'
                    size={'sm'} onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title={t("Export To CSV")}
                      className='pl-1' size='lg' />
                  </Button>}
                  {!isOpenFormModal && props.exportRequired && <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    // ref={(r) => csvLinkRef = r}
                    target="_blank" >
                  </CSVLink>}
                  {!isOpenFormModal && props.sample ?
                    <Button color="primary"
                      size="sm"
                      className="p-1 mt-1 mb-0"
                      onClick={() => bulkUploadMoadal()}>
                      <FontAwesomeIcon
                        icon='upload'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Bulk Upload")}
                        onClick={() => bulkUploadMoadal()} />
                    </Button>
                    : null}

                  {!isOpenFormModal && props.gridRequried ? <ButtonGroup className='mb-0 mr-3'>
                    <Button color="primary"
                      outline={viewType === 'grid' ? false : true}
                      size="sm"
                      className="p-1 ml-auto mt-1 mb-0"
                      onClick={() => changeViewType('grid')}
                    >
                      <FontAwesomeIcon
                        icon='grip-horizontal'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Grid")}
                        onClick={() => changeViewType('grid')}
                      />
                    </Button>
                    <Button color="primary"
                      size="sm"
                      outline={viewType === 'list' ? false : true}
                      className="p-1 ml-auto mt-1 mb-0"
                      onClick={() => changeViewType('list')}
                    >
                      <FontAwesomeIcon
                        icon='list'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("List")}
                        onClick={() => changeViewType('list')}
                      />
                    </Button>
                  </ButtonGroup> : null}

                  {!isOpenFormModal && props.settingsRequired ?
                    <span> &nbsp;
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                        onClick={() => openShowHideColumnsModal()}
                      >
                        <FontAwesomeIcon
                          icon='cog'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={t("Settings")}
                          onClick={() => openShowHideColumnsModal()}
                        />
                      </Button> </span> : null}

                  {/* Add button */}
                  {props.addRequired && !isOpenFormModal ?
                    <a href={`/create_${props.routeTo}`}  >
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                      //not using for now
                      // onClick={() => openFormModal('add')}
                      >
                        <FontAwesomeIcon
                          icon='plus'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={t("Add")}
                        //not using for now
                        // onClick={() =>   openFormModal('add')}
                        />
                      </Button>
                    </a>
                    : null}

                  {/* priority  in Issues*/}
                  {!isOpenFormModal &&
                    <span> &nbsp;
                      <Button
                        color="secondary"
                        size="sm"
                        className={props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                        onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                        <FontAwesomeIcon
                          icon='sync-alt'
                          size='lg'
                          data-toggle="tool-tip" title={t("Refresh")}
                          color={config.templateColor}
                          className='refreshIcon pl-1' />
                      </Button> </span>}
                </div>
              </span>
            </span>
          </div>
        </div >

      </div >
    )
  }
  const onGlobalSerachFilterChange = async (event) => {
    if (event) {

      await onFilterChange(event)
    }

  }
  const getHeader = () => {
    const { t } = props;
    return (
      /**Not using For Now */
      // <div className="row">
      //   {/* global Search */}
      //   <div className='col-6'>
      //   </div>
      //   <div className='col-6'>
      //     <div className='serachAlignment float-right' >
      //       <div className="p-inputgroup">
      //         <span className="p-inputgroup-addon">
      //           <i className="pi pi-search" ></i>
      //         </span>
      //         <InputText
      //           type="search"
      //           name='globalSearch'
      //           onChange={(e) => onFilterChange(e)}
      //           placeholder={props.globalSearch ? props.globalSearch : 'search'}
      //           size="30" />
      //       </div>
      //     </div>
      //   </div>
      // </div >

      <div>
        <h4><span className='postionRelative pt-2'>
          <b
          ><a onClick={() => closeFormModal} >
              {t(props.type)}
            </a>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
          </b>
        </span>
        </h4>
        <div className="row">
          {/* global Search */}
          <div className={(props.dateSearchRequired) ? "col-9" : 'col-6'}>
            {(props.dateSearchRequired) ?
              <div className='row'>
                <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                </RenderIntervalDatePickerField>
                <Button className="col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
              </div>
              : null}
          </div>
          {/**@modalFix */}
          {          /**Not using For Now */
          }
          {/* {

          props.globalSearch ?
            <div className={(props.dateSearchRequired) ? "col-3" : 'col-6'}>
              <div className='serachAlignment float-right' >
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    < i className="pi pi-search" ></i >
                  </span>
                  <InputText
                    type="search"
                    name='globalSearch'
                    id='globalSearch'
                    value={state['globalSearch']}
                    onChange={(e) => onFilterChange(e)}
                    placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                    size="25" />
                </div>
              </div>
            </div> : null
          } */}
        </div >
        <div className='row'>
          {/* global Search */}
          <div className='col-sm-12'>
          </div>
          <div className='col-12  pb-1'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {config.paginationPosition == 'top' ? getPaginator() : null}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {props.filterRequired ?
                  <span className='mr-3' ref={closeMultiSelectDropdown}>
                    <span className='multiSelectDropDownCard '>
                      <span onClick={toogleMultiSelect}>
                        {t('Filters')}
                      </span>
                      <span onClick={toogleMultiSelect}>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className='ml-1'
                          color='grey'
                        />
                      </span>
                      {
                        showMultiSelectDropDown ?
                          <Card className=' itemsWarperCard' >
                            {
                              tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                                return (
                                  item.displayInFilter && item.show && < div >
                                    <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                    <ul className='pl-0'>
                                      {
                                        item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                          return (
                                            <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                              <span className='chekcIconAdjust'>
                                                <Checkbox
                                                  // onChange={e => setChecked(e.checked)}
                                                  checked={optionsItem.checkIcon}>
                                                </Checkbox>
                                                {/* <FontAwesomeIcon
                                           icon='check'
                                           color={optionsItem.checkIcon ? 'grey' : 'white'}
                                         /> */}
                                              </span>
                                              <span className='chekcIconAdjustLabel'>
                                                {t(optionsItem.label)}
                                              </span>
                                            </div>
                                          )
                                        }) : null
                                      }
                                    </ul>
                                    <hr className='my-0'></hr>
                                  </div>
                                )
                              }) : null
                            }
                          </Card>
                          : null}
                    </span>

                  </span>
                  : null}

                {/**Not using For Now */}
                {/* {!isOpenFormModal ? <MultiSelect
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={state['multiActions'] ? state['multiActions'] : null}
                  options={props.actionsTypes}
                  onChange={(e) => onFilterChange(e, 'dropdownFilter')}
                /> : null} */}

                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={actions}
                  disabled={selectedRows && selectedRows.length > 0 ? false : true}
                  options={actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => onActionsChange(e, 'dropdownFilter')}
                />

                {props.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => print()}
                    />
                  </Button>
                  : null}
                {props.exportRequired && <Button color="primary"
                  className='p-1 ml-auto mb-0 mt-1'
                  size={'sm'} onClick={getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                    className='pl-1' size='lg' />
                </Button>}
                {/**@ModalFix */}
                {/* {props.exportRequired && <CSVLink
                  data={exportData}
                  filename={`${props.type}.csv`}
                  className="hidden text-white"
                  ref={(r) => csvLinkRef = r}
                  target="_blank" >
                </CSVLink>} */}
                {props.sample ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 mt-1 mb-0"
                    onClick={() => bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => bulkUploadMoadal()} />
                  </Button>
                  : null}

                {props.gridRequried && <ButtonGroup className='mb-0 mr-3'>
                  <Button color="primary"
                    outline={viewType === 'grid' ? false : true}
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => changeViewType('grid')}
                    />
                  </Button>
                  <Button color="primary"
                    size="sm"
                    outline={viewType === 'list' ? false : true}
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => changeViewType('list')}
                    />
                  </Button>
                </ButtonGroup>}
                {props.settingsRequired && <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>}

                {/* Add button */}
                {props.addRequired ?
                  <a href={`/create_${props.routeTo}`} >
                    <Button color="primary"
                      size="sm"
                      className="p-1 ml-auto mt-1 mb-0"
                    //not using for now
                    // onClick={() => openFormModal('add')}
                    >
                      <FontAwesomeIcon
                        icon='plus'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Add")}
                      //not using for now
                      // onClick={() => openFormModal('add')}
                      />
                    </Button></a>
                  : null}

                {/* priority  in Issues*/}
                <Button
                  color="secondary"
                  size="sm"
                  className={props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                  onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button>

              </span>
            </span>
          </div>
        </div>

      </div >
    )
  }
  const getCardHeader = () => {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{props.type}{props.type == 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>
            {/* //not using for now */}
            {/* Export to csv */}
            {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
            {/* {props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={bulkUploadMoadal} />
              </Button>
              : null} */}
            {/* Add Settings Button */}
            {props.settingsRequired ?
              <Button
                raised
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button1 */}
            {props.addRequired ?
              <a href={`/create_${props.routeTo}`} >
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto"
                // onClick={() => openFormModal('add')}
                >
                  <FontAwesomeIcon
                    icon='plus'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Add"
                  //  onClick={() => openFormModal('add')}
                  />
                </Button></a>
              : null}
            {/* priority  in Issues*/}
            <Button
              color="secondary"
              size="sm"
              className={props.addRequired ? "p-1" : 'p-1 ml-auto'}
              onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
      // </CardHeader>
    )
  }
  const cancelUserPwdResetModal = async () => {
    setOpenUserPasswordResetModal(false);
    setActions('');
  }
  const handleSearchDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }

  const searchInDateRanges = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format('YYYY-MM-DD'));
      let endDate = (moment(endDate).format('YYYY-MM-DD'));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        { 'key': searchInDateRangeField, 'value': startDate, 'type': 'gte' },
        { 'key': searchInDateRangeField, 'value': endDate, 'type': 'lte' }];
      getDataFromServer(filterCriteria, tabType);
    }
    else {
      alert('no dates selected');
    }
  }

  const getColumns = (e, d) => {
    // <Loader loader={isLoading} />
    const { t } = props
    //not using for now
    // const self = this;
    // self.e = e;
    // self.d = d;
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              minwidth: item.width,
              //  alignItems:'center'

            }}
            bodyStyle={item.capitalizeTableText ? {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            } : {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            editRequired={e}
            deleteRequired={d}
            header={<span style={{ fontWeight: "bold" }}>{t(item.header)}</span>}
            // header={<span >{t(item.header)}</span>}

            changeFieldName={item.changeFieldName}
            body={changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 13, textAlign: item.textAlign,
              whiteSpace: 'break-spaces',
              // color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={item.filter ? item.filter : false}
            showFilterMenu={false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
            filterElement={item.filterElement ?
              multiSelectFilterTemplate


              : item["type"] == "date" ?
                dateFilterTemplate

                : null
            }
            selectionMode={item.selectionMode}
          />
        )

        return column;
      })
    }

  }

  const isOpenOrderConfirmation = async (e) => {
    openFormModal(tableRowData, "edit", true)
    setOpenOrderConfirmModal(false);
  };

  const closeOrderConfirmation = () => {
    setTableRowData({});
    setOpenOrderConfirmModal(false);
  }

  const multiSelectFilterTemplate = (options) => {
    let filterOptions = [];
    filterOptions = getMultiSelectOptions(options);
    return <MultiSelect value={options.value} options={filterOptions} maxSelectedLabels={1} optionalLabel="label" onChange={(e) => options.filterApplyCallback(e.value, options.index)} placeholder="Any" className="p-column-filter" />;
  };

  const getMultiSelectOptions = (options) => {
    let tableFields = props.getTableFields();
    let i = tableFields.findIndex((col) => col.field === options.field);
    if (i > -1 && tableFields[i].filterElement) {
      return tableFields[i].filterElement;
    } else {
      return [];
    }
  }

  const dateFilterTemplate = (options) => {
    return <Calendar value={options.value} showButtonBar selectionMode="range" onChange={(e) => options.filterApplyCallback(e.value, options.index)} />;
  };

  const getDataTable = () => {

    return (
      <DataTable
        rowClassName={rowClassName}
        // ref={(el) => dt = el}
        lazy
        value={allUsersData}
        // header={getHeader()}
        totalRecords={totalRecordsLength}
        paginator={false}
        resizableColumns={true}
        // columnResizeMode="expand"
        onSort={sortChange}
        // globalFilter={globalFilter}
        onFilter={onFilterColumns}
        scrollable={true}
        // selection={false}
        onSelectionChange={e => onSelectRowsUpdate(e)}
        scrollHeight='1000px'
        // style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        // sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        selection={selectedRows}
        selectionMode={props.selectionMode ? null : 'multiple'}
        metaKeySelection={false}
        loading={isLoading}
        style={allUsersData && allUsersData.length == 0 ?
          { textAlign: 'center' }
          : null}
      // filterDisplay="row"
      >
        {/* {isLoading ? (
          <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
            <Loader loader={isLoading} />
          </div>
        ) : (
          getColumns(props.editRequried, props.deleteRequried)
        )} */}
        {getColumns(props.editRequried, props.deleteRequried)}
      </DataTable>
    )
  }

  const getTabInfo = () => {
    return null;
  }

  const getPaginator = () => {
    return (
      <PaginatorComponent
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        onPageChange={onPageChange}
        isWeb={true}
      />
    )
  }

  //getGridView
  const getGridView = () => {
    return (
      <div className='row   ml-lg-0 pr-1'>
        {
          allUsersData && allUsersData.length > 0 ?
            allUsersData.map((item, i) => {
              return (
                <div className='col-sm-6 col-md-4 col-lg-3 px-1' key={i}>
                  <Card className='pb-2' >
                    <CardBody className='tableCardBody'>
                      {
                        tablefieldsToShow && tablefieldsToShow.length && tablefieldsToShow.length > 0 ?
                          tablefieldsToShow.map((elememt, index) => {
                            return (
                              <div className={(elememt.displayInSettings === true) ? `col-12  ` : 'd-none'} key={index}>
                                <div >
                                  {/* {changeFieldValues(item, element)} */}
                                  {
                                    elememt.fieldType === 'Link' ?
                                      <div className='d-flex'>
                                        <span
                                          style={elememt.style}
                                          onClick={() => openViewModal(item, 'view')}>
                                          <b> {item[elememt.field]}</b>
                                        </span>
                                      </div>
                                      :
                                      elememt.fieldType === 'Badge' ?
                                        <div style={elememt.style}>
                                          <Badge color={item.status == 'Active' ? 'success' : item.status == 'Inactive' ? 'warning' : item.status == 'Pending' ? 'danger' : item.status == 'Reject' ? 'error' : item.status == 'Completed' ? 'primary' : 'info'}>
                                            {item[elememt.field]}
                                          </Badge>
                                        </div> :
                                        elememt.fieldType === 'Role' ?
                                          <div style={elememt.style}>
                                            <Badge pill
                                              color='success'
                                            >
                                              {item[elememt.field]}
                                            </Badge>
                                          </div>
                                          : elememt.fieldType === 'Date' ?
                                            <div>
                                              {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                            </div>
                                            : elememt.fieldType === 'Time' ?
                                              <div>
                                                {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                              </div>
                                              : elememt.fieldType === 'Array' ?
                                                <span style={element.style}>
                                                  {flattenArray(item[elememt.field])}
                                                </span>
                                                : elememt.fieldType === "dropDown" ?
                                                  <div style={elememt.style}>
                                                    {getBadgeData(elememt, item[elememt.field])}
                                                  </div> : elememt.fieldType === "relateAutoComplete" ?
                                                    < span style={elememt.style}>
                                                      {item[elememt.field] && elememt.searchField && item[elememt.field][elememt.searchField] ? item[elememt.field][elememt.searchField] : null}
                                                    </span>
                                                    : <div style={elememt.style}>
                                                      <span
                                                      >{item[elememt.field]}</span></div>
                                  }
                                </div>
                              </div>
                            )
                          }) : null
                      }
                    </CardBody>
                  </Card>
                </div>

              )
            }) : null
        }
      </div>
    )
  }
  const getBadgeData = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return (<Badge color={mcolor} pill >{value}</Badge>)
  }

  const getTotalCard = () => {
    if (viewType === 'list' && displayViewOfForm != "sideForm") {
      return (
        <Card className='cardForListMargin' >
          <CardBody className='tableCardBody'>
            {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
            {getTabInfo()}
            {!isOpenFormModal && displayViewOfForm === 'screen'
              ? getDataTable() : displayViewOfForm === 'modal' ? getDataTable() : null}
            {isOpenFormModal ? props.screen == "wishlist" ? getWishlistData() : getFormModal() : null}
            {config.paginationPosition == 'bottom' ? getPaginator() : null}
          </CardBody>
        </Card>
      )
    }
    if (viewType === 'list') {
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "right") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}
              {isOpenFormModal ? (
                <div className='row'>
                  <div className='col-9'>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}
                    {/* {getFormModal()} */}
                    {config.paginationPosition == 'bottom' ? getPaginator() : null}
                  </div>
                  <div className='col-3' style={{ borderLeftStyle: 'solid', borderLeftColor: 'lightgrey' }}>
                    {getFormModal()}
                  </div>
                </div>)
                : null
              }
              {config.paginationPosition == 'bottom' ? getPaginator() : null}
            </CardBody>
          </Card>
        )
      }
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "left") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}

              {isOpenFormModal ? (
                <div className='row'>
                  <div className='col-3' style={{ borderRightStyle: 'solid', borderRightColor: 'lightgrey' }}>
                    {getFormModal()}
                  </div>
                  <div className='col-9'>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}
                    {/* {getFormModal()} */}
                    {config.paginationPosition == 'bottom' ? getPaginator() : null}
                  </div>
                </div>)
                : null
              }

              {config.paginationPosition == 'bottom' ? getPaginator() : null}
            </CardBody>
          </Card>
        )
      }
    }
    if (viewType === 'grid') {
      if (displayViewOfForm == "sideForm") {
        return (
          <div>
            <Card className={' pb-2 cardForGridMargin'} >
              <CardBody className='tableCardBody'>
                {!isOpenFormModal ? getScreenHeader() : null}
                {getTabInfo()}
              </CardBody>
            </Card>
            {!isOpenFormModal ? getGridView() : null}
          </div>
        )
      }
    }
    if (viewType === 'grid' && displayViewOfForm != "sideForm") {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0'>
              {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
              {getTabInfo()}
              {isOpenFormModal ? getFormModal() : null}
            </CardBody>
          </Card>
          {!isOpenFormModal && displayViewOfForm === 'screen' ? getGridView() : displayViewOfForm === 'modal' ? getGridView() : null}
        </div>
      )
    }
  }

  const getMobileCard = () => {
    return (
      <div>
        <Loader loader={isLoading} />
        {isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {isOpenFormModal ? getMobileForm() : getGridView()}
        {!isOpenFormModal ? <PaginatorComponent
          totalRecords={totalRecordsLength}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  const flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  const getMobileForm = () => {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {getScreenHeader()} */}
              {getFormModal()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
  const getFormFields = () => {
    formModalRef.getFormFields()
  }

  const getWishlistData = () => {
    return <FormModal
      // onRef={(ref) => (this.formModalRef = ref)}          
      openFormModal={isOpenFormModal}
      formFields={props.getOrderFields}
      closeFormModal={closeFormModal}
      actionsTypes={false}
      selectedId={props.selectedId}
      type={props.type}
      screen="wishlist"
      displayViewOfForm="modal"
      tableRowData={tableRowData}
      modalHeaderName="To Order List"
      filterCriteria={filterCriteria}
      apiUrl={props.wishlistUrl}
      getDataFromServer={getDataFromServer}
      storageUnitId={props.yourQueryParam}
      adminCompanyId={props.companyId}
    />
  }

  const getFormModal = () => {
    return (
      <FormModal
        onRef={(ref) => formModalRef = ref}
        openFormModal={isOpenFormModal}
        tableRowData={tableRowData}
        allUsersData={allUsersData}
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        closeFormModal={closeFormModal}
        // closeRejectModal={closeRejectModal}

        modalHeaderName={props.modalHeaderName}
        type={props.type}
        tablefieldsToShow={tablefieldsToShow}
        originalTableFields={originalTableFields}
        formType={formType}
        formFields={props.formFields}
        getDataFromServer={getDataFromServer}
        editRequired={props.editRequired}
        getEditInfoKeys={props.getEditInfoKeys}
        filterExtension={props.filterExtension}
        idNotRequired={props.idNotRequired}
        apiUrl={props.formUrl ? props.formUrl : apiUrl}
        role={loginRole}
        tabType={tabType}
        getDoctorPostingFields={props.getDoctorPostingFields}
        categoryNames={props.categoryNames}
        companiesList={props.companiesList}
        adminCompanyId={props.companyId}
        getStorageunitName={props.storageUnitName}
        getSettings={getSettings}
        filterCriteria={filterCriteria}
        menuList={props.menuList}
        routeTo={props.routeTo}
        displayViewOfForm={displayViewOfForm}
        displayBreadCrumbValue={displayBreadCrumbValue}
        displayBreadCrumbField={displayBreadCrumbField}
        userStatus={userStatus}
        addSelectedRecord={props.addSelectedRecord}
        actionsTypes={actionsTypes}
        entityType={props.entityType}
        setFilterCriteriaForActivities={setFilterCriteriaForActivities}
        getColorFromOptions={getColorFromOptions}
        selectedId={props.selectedId || tableRowData?._id}
        getCartIncrement={getCartIncrement}
        isOpenWishlist={isOpenWishlist}
        productQuantitesUrl={props.productQuantitesUrl}
        storageUnitId={props.yourQueryParam}
        categoryType={categoryType}
        customFormDataSet={props.customFormDataSet}
        customFormDataManuplate={props.customFormDataManuplate}
        setDirty={setDirty}

      />
    )
  }

  const getViewModal = () => {
    return (
      <ViewModal
        type={props.type}
        openViewModal={openViewModal}
        displayViewOfForm={displayViewOfForm}
        rowData={rowData}
        formFields={props.formFields}
        onRef={(ref) => (viewModalRef = ref)}
        closeViewModal={closeViewModal}
        locationId={locationId}
        drawers={drawers}
      />
    )
  }

  const getOpenShowHideColumnsModal = () => {
    return (
      <ShowHideColumnsModal
        viewType={viewType}
        isOpenShowHideColumnsModal={isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={closeShowHideColumnsModal}
        tableFields={originalTableFields}
        type={props.type}
      />
    )
  }

  const getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={deleteSelectedRow}
        confirmModalText={confirmModalText}
      />
    )
  }

  const getPreviewModal = () => {
    return (
      <PreviewModal
        isPreviewModal={isPreviewModal}
        closePreviewModal={closePreviewModal}
        emailTemplateData={emailTemplateData}
        type={props.type}
      />
    )
  }
  const getSendEMailFields = () => {
    return ([{
      required: true,
      value: '',
      type: 'text',
      name: 'email',
      label: 'To',
      id: 'email',
      placeholder: 'Email'
    },
    {
      required: true,
      value: '',
      type: 'text',
      name: 'subject',
      label: 'Subject',
      id: 'subject',
      placeholder: 'Subject'
    },
    {
      required: true,
      value: '',
      type: 'ckeditor',
      name: 'templateText',
      label: 'Body',
      id: 'emailTemplate',
      placeholder: 'name'
    }])
  }
  const getSendEmailModal = () => {
    return (
      <SendEmailModal
        onRef={(ref) => SendEmailModalRef = ref}
        openSendEmailModal={openSendEmailModal}
        closeSendEmailModal={closeSendEmailModal}
        type={props.type}
        item={selectedRows}
        formFields={getSendEMailFields}
        parentFormFields={props.formFields()}
        apiUrl={props.routeTo}
      />
    )
  }
  const getBulkUploadModal = () => {
    return (
      <BulkUploadModal
        // onRef={(ref) => bulkUploadMoadalRef = ref}
        bulkApi={props.bulkApi}
        type={props.type}
        sample={props.sample}
        sampleFilePath={`${bulkSampleFiles[props.apiUrl]}`}
        // sampleFilePath={props.sampleFilePath}
        openBulkUploadModal={openBulkUploadModal}
        closeBulkModal={closeBulkModal}
        reload={getDataFromServer}
      />
    )
  }

  const getConfirmatioinModal = () => {
    return (
      <ConfirmationModal
        formType={formType}
        onRef={(ref) => (confirmRef = ref)}
        openConfirmationModal={openConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirm={clickConfirm}
        text={confirmText}
      />
    )
  }

  const getSessionExpiryModal = () => {
    return (
      <SessionExpiryModal
        SOpen={sessionExpiryModal}
      />
    )
  }

  const getWishlistPopUp = () => {
    return (
      <OrderConfirmationModal
        Order={openOrderConfirmModal}
        openOrderConfirmation={isOpenOrderConfirmation}
        closeOrderConfirmation={closeOrderConfirmation}
      />
    )
  }
  const getWishlistModal = () => {
    const { yourQueryParam, type } = props
    return (
      <WishlistModal
        openWishlistModal={isOpenWishlist}
        formType={"Orders"}
        type={type}
        storageUnitId={yourQueryParam}
        allUsersData={allUsersData}
        filterCriteria={filterCriteria}
        ordersResponse={ordersResponse} // Pass ordersResponse state
        setOrdersResponse={setOrdersResponse} // Pass setter function        
        adminCompanyId={props.companyId}
        closeFormModal={closeWishlistModal}
        getDataFromServer={getDataFromServer}
        categoryType={categoryType}

      // getCartIncrement = {getCartIncrement}
      // setCartCount = {setCartCount}

      />
    )
  }

  const getAddQuantitesForm = () => {
    return (
      <AddQuantitesModal
        openQuantitesModal={isOpenQuantitesModal}
        closeFormModal={closeAddQuantitesModal}
        type={props.type}
        formType={formType}
        apiUrl={props.apiUrl}
        tableRowData={tableRowData}
        allUsersData={allUsersData}
        displayViewOfForm={props.displayViewOfForm}
        productQuantitesUrl={props.productQuantitesUrl && props.productQuantitesUrl}
        storageUnitsLocalData={props.storageUnitsLocalData}
        tabType={props.tabType}
        filterCriteria={filterCriteria}
        adminCompanyId={props.companyId}
        getDataFromServer={getDataFromServer}
        storageUnitName={props.storageUnitName}
        facilityName={props.facilityName}
        facilityId={props.facilityId}
        companyId={props.companyId}
        storageUnitId={props.yourQueryParam}
        categoryType={categoryType}
      />
    )
  }

  const getCategorysModal = () => {

    return (
      <AddCategoryModal
        openWishlistModal={openCategoryModal}
        closeFormModal={closeCategoryModal}
        apiUrl={props.apiUrl}
        getDataFromServer={getDataFromServer}
        categoryType={categoryType}
        formType={formType}
        adminCompanyId={props.companyId}

      />
    )
  }

  const getStorageUnitModal = () => {
    return (
      <UploadIconModal
        openStorageUnitModal={openStorageUnitModal}
        closeFormModal={closeStorageUnitModal}
        getDataFromServer={getDataFromServer}
        tableRowData={tableRowData}
        categoryType={categoryType}
        storageUnitId={props.yourQueryParam}
      />
    )
  }
  const getRecommendedInventoryModal = () => {
    return (
      <InventoryModal
        openRecommendedInventoriesModal={openRecommendedInventoriesModal}
        closeRecommendedInventoryModal={closeRecommendedInventoryModal}
        tableRowData={tableRowData}

      />
    );
  };


  return (
    <div >
      {width <= 576 && getMobileCard()}
      {width >= 577 && getTotalCard()}
      {isOpenShowHideColumnsModal ? getOpenShowHideColumnsModal() : null}
      {openDeleteModal ? getDeleteRowModal() : null}
      {openViewModal ? getViewModal() : null}
      {props.sample ? getBulkUploadModal() : null}
      {isOpenConfirmationModal ? getConfirmatioinModal() : null}
      {sessionExpiryModal ? getSessionExpiryModal() : null}
      {isPreviewModal ? getPreviewModal() : null}
      {redirectToRoute ? <Navigate to={`/${props.routeTo}`} /> : null}
      {openSendEmailModal ? getSendEmailModal() : null}
      {isOpenWishlist ? getWishlistModal() : null}
      {isOpenQuantitesModal ? getAddQuantitesForm() : null}
      {openCategoryModal ? getCategorysModal() : null}
      {openStorageUnitModal ? getStorageUnitModal() : null}
      {openOrderConfirmModal ? getWishlistPopUp() : null}
      {openRecommendedInventoriesModal ? getRecommendedInventoryModal() : null}
    </div >
  );

}

const mapStateToProps = state => {
  return { articles: state.articles };
};
const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);
