const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employee: "employees",
  employees: "employees",
  companyUser: "users",
  facilities: "facilities",
  storageUnits: "storageUnits",
  cartLocations: "storageUnits",
  supplyCabinets: "storageUnits",
  oRSuites: "storageUnits",
  minInventoryByState: "mininventorybystates",
  minInventoryByStates: "minInventoryByStates",
  categories: "categories",
  products: "products",
  storageUnitItems: "productQuantities/getStorageUnitItemsCount",
  productQuantities: "productQuantities",
  getProductQuantities: 'productQuantities/getLotIds',
  inventoryCheckList: "productQuantities/getInventoryCheckListProductQuantitiesCount",
  inventoryCheckLists: "storageUnits/getStorageUnits",
  expirationItems: "productQuantities/getExpiredProductQuantitiesCount",
  expiredItems: "productQuantities/getExpiredProductQuantities",
  missingItemsCount: "productQuantities/getMissingCount",
  missingItems: "productQuantities/getMissingItems",
  recallItems: "recalls",
  itemsRecalled: "itemsrecalleds",
  itemsRecalleds: "itemsRecalleds",
  recall: "recalls",
  recalls: "recalls",
  storageUnitChecks: "alerts",
  storageUnitChecksRoute: "storageUnitChecks",
  batteryChecks: "alerts",
  batteryChecksRoute: "batteryChecks",
  facilityStaffs: "companyUsers",
  orders: "orders",
  orderlist: 'orders/getOrderList',
  placedOrders: "orders",
  fulfilledOrders: "orders",
  requiredInventory: "requiredinventories",
  requiredInventories: "minInventoryByProducts/getNewMobileList",
  minInventoryByProducts: "minInventoryByProducts",
  distributors: "distributors",
  helps: "helps",
  recommendedInventories: "minInventoryByStates",
  companyUsers: "companyUsers",
  employees: 'employees',
  alerts: "alerts",
  notifications: "notifications",
  DashboardInventoryFlags: 'dashboard/inventoryFlags',
  cartCount: "cartLists/getCartByStorageUnitId",
  wishlist: "cartLists",
  payments: "payments",
  tickets: 'tickets',
  clinicsCount: 'companyUsers/facilitiesCount',
  dashboarddata: 'companyUsers/getCompaniesAndFacilitiesCount',
  autorecalls: 'recalls',
  // productQuantities:"productQuantities"
  companies: "companyUsers/companiesList",
  invoices: 'invoices',
  multiOrders: 'cartLists/multiCartList',
  OrdersCount: 'orders/getOrdersListCount',
  numberOfdrawers: 'storageUnits/createSubLocations',
  deviceManagements:"deviceManagements",
  locationTrackers:"locationTrackers",
  accessPoints:"accessPoints"

};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;
