/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter, Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';
import Dashboard from '../Dashboard/index';
// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Employees from '../Cruds/Employees/index';
import Facilities from '../Cruds/Facilities/index';
import StorageUnits from '../Cruds/StorageUnits/index';
import CartLocations from '../Cruds/CartLocations/index';
import SupplyCabinets from '../Cruds/SupplyCabinets/index';
import ORSuites from '../Cruds/ORSuites/index';
import MinInventoryByStates from '../Cruds/MinInventoryByStates/index';
import Categories from '../Cruds/Categories/index';
import Products from '../Cruds/Products/index';
import StorageUnitItems from '../Cruds/StorageUnitItems/index';
import ProductQuantities from '../Cruds/ProductQuantities/index';
import InventoryCheckLists from '../Cruds/InventoryCheckLists/index';
import InventoryCheckListsCount from '../Cruds/InventoryCheckLists Count/index';
import ExpirationItems from '../Cruds/ExpirationItems/index';
import ExpiredItems from '../Cruds/ExpiredItems/index';
import MissingItems from '../Cruds/MissingItems/index';
import MissingItemsCount from '../Cruds/MissingItems Count';
import OrdersCount from '../Cruds/OrdersCount/index.jsx';
import RecallItems from '../Cruds/RecallItems/index';
import ItemsRecalleds from '../Cruds/ItemsRecalleds/index';
import Recalls from '../Cruds/Recalls/index';
import StorageUnitChecks from '../Cruds/StorageUnitChecks/index';
import BatteryChecks from '../Cruds/BatteryChecks/index';
import FacilityStaffs from '../Cruds/FacilityStaffs/index';
import Orders from '../Cruds/Orders/index';
import PlacedOrders from '../Cruds/PlacedOrders/index';
import FulfilledOrders from '../Cruds/FulfilledOrders/index';
import RequiredInventories from '../Cruds/RequiredInventories/index';
import MinInventoryByProducts from '../Cruds/MinInventoryByProducts/index';
import Distributors from '../Cruds/Distributors/index';
import Helps from '../Cruds/Helps/index';
import RecommendedInventories from '../Cruds/RecommendedInventories/index';
import Payments from "../Payments/StripeContainer"
import Alerts from '../Cruds/Alerts/index';
import Notifications from '../Cruds/Notifications/index';
import CompanyUsers from "../Cruds/CompanyUsers/index"
import Tickets from '../Cruds/Tickets/index.jsx';
// import Employees from '../Cruds/Employees/index';
import EmployeeRoles from '../Cruds/EmployeeRoles/components/EmployeeRoles.jsx';
import AutoRecalls from '../Cruds/AutoRecalls/index.jsx';
import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';
import ActiveCompanies from '../Cruds/ActiveCompanies/index';
import RecommendedInventoriesDataTable from '../Cruds/CommonDataTable/RecommendedInventoriesDataTable.jsx';
import Bills from '../Cruds/Invoice/index.jsx';
import CompanyPayments from '../Cruds/CompanyPayments/index.jsx';
import DeviceManagements from '../Cruds/DeviceManagements/index.jsx';
import LocationTrackers from '../Cruds/LocationTrackers/index.jsx';
import AccessPoints from '../Cruds/AccessPoints/index.jsx';
const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
        <Routes>
          <Route path='/sampleform'
            element={<SampleForm />}
          ></Route>

          {/* <Route
            path="/create_autoRecall"
            element={
              roles["Recall"] &&
                (roles["Recall"] === "Edit")
                ? <AutoRecalls />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_autoRecall:id"
            element={
              roles["Recall"] &&
                (roles["Recall"] === "Edit")
                ? <AutoRecalls />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_autoRecall/:id"
            element={
              roles["Recall"] &&
                (roles["Recall"] === "Edit" || roles["Recall"] === "View")
                ? <AutoRecalls />
                : <ErrorNotFound />
            }
          /> */}
          <Route exact
            path="/employees"
            element={
              roles['Employees'] &&
                (roles['Employees'] === "Edit" || roles['Employees'] === "View")
                ? <Employees />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/employees/:id"
            element={
              roles['Employees'] &&
                (roles['Employees'] === "Edit" || roles['Employees'] === "View")
                ? <Employees />
                : <ErrorNotFound />
            } />
          <Route
            path="/invoicess"
            element={
              roles["Invoices"] &&
                (roles["Invoices"] === "Edit" || roles["Invoices"] === "View")
                ? <Bills />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/create_employees"
            element={
              roles['Employees'] &&
                (roles['Employees'] === "Edit")
                ? <Employees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_employees/:id"
            element={
              roles['Employees'] &&
                (roles['Employees'] === "Edit")
                ? <Employees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_employees/:id"
            element={
              roles['Employees'] &&
                (roles['Employees'] === "Edit" || roles['Employees'] === "View")
                ? <Employees />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/facilitiess"
            element={
              roles['Facilities'] &&
                (roles['Facilities'] === "Edit" || roles['Facilities'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />

          <Route exact
            path="/batteryChecks/:id"
            element={
              roles['Battery Checks'] &&
                (roles['Battery Checks'] === "Edit" || roles['Battery Checks'] === "View")
                ? <BatteryChecks />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/recallItems/:id"
            element={
              roles['Recall Items'] &&
                (roles['Recall Items'] === "Edit" || roles['Recall Items'] === "View")
                ? <RecallItems />
                : <ErrorNotFound />
            } />

          <Route exact
            path="/facilities"
            element={
              roles['Facilities'] &&
                (roles['Facilities'] === "Edit" || roles['Facilities'] === "View")
                ? <Facilities />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_facilities"
            element={
              roles['Facilities'] &&
                (roles['Facilities'] === "Edit")
                ? <Facilities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_facilities/:id"
            element={
              roles['Facilities'] &&
                (roles['Facilities'] === "Edit")
                ? <Facilities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_facilities/:id"
            element={
              roles['Facilities'] &&
                (roles['Facilities'] === "Edit" || roles['Facilities'] === "View")
                ? <Facilities />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/storageUnitss"
            element={
              roles['Storage Units'] &&
                (roles['Storage Units'] === "Edit" || roles['Storage Units'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />


          <Route exact
            path="/storageUnits"
            element={
              roles['Storage Units'] &&
                (roles['Storage Units'] === "Edit" || roles['Storage Units'] === "View")
                ? <StorageUnits />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_storageUnits"
            element={
              roles['Storage Units'] &&
                (roles['Storage Units'] === "Edit")
                ? <StorageUnits />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_storageUnits/:id"
            element={
              roles['Storage Units'] &&
                (roles['Storage Units'] === "Edit")
                ? <StorageUnits />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_storageUnits/:id"
            element={
              roles['Storage Units'] &&
                (roles['Storage Units'] === "Edit" || roles['Storage Units'] === "View")
                ? <StorageUnits />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/cartLocations"
            element={
              roles['Cart Locations'] &&
                (roles['Cart Locations'] === "Edit" || roles['Cart Locations'] === "View")
                ? <CartLocations />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_cartLocations"
            element={
              roles['Cart Locations'] &&
                (roles['Cart Locations'] === "Edit")
                ? <CartLocations />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_cartLocations/:id"
            element={
              roles['Cart Locations'] &&
                (roles['Cart Locations'] === "Edit")
                ? <CartLocations />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_cartLocations/:id"
            element={
              roles['Cart Locations'] &&
                (roles['Cart Locations'] === "Edit" || roles['Cart Locations'] === "View")
                ? <CartLocations />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/supplyCabinets"
            element={
              roles['Supply Cabinets'] &&
                (roles['Supply Cabinets'] === "Edit" || roles['Supply Cabinets'] === "View")
                ? <SupplyCabinets />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_supplyCabinets"
            element={
              roles['Supply Cabinets'] &&
                (roles['Supply Cabinets'] === "Edit")
                ? <SupplyCabinets />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_supplyCabinets/:id"
            element={
              roles['Supply Cabinets'] &&
                (roles['Supply Cabinets'] === "Edit")
                ? <SupplyCabinets />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_supplyCabinets/:id"
            element={
              roles['Supply Cabinets'] &&
                (roles['Supply Cabinets'] === "Edit" || roles['Supply Cabinets'] === "View")
                ? <SupplyCabinets />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/oRSuites"
            element={
              roles['OR Suites'] &&
                (roles['OR Suites'] === "Edit" || roles['OR Suites'] === "View")
                ? <ORSuites />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_oRSuites"
            element={
              roles['OR Suites'] &&
                (roles['OR Suites'] === "Edit")
                ? <ORSuites />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_oRSuites/:id"
            element={
              roles['OR Suites'] &&
                (roles['OR Suites'] === "Edit")
                ? <ORSuites />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_oRSuites/:id"
            element={
              roles['OR Suites'] &&
                (roles['OR Suites'] === "Edit" || roles['OR Suites'] === "View")
                ? <ORSuites />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/minInventoryByStates"
            element={
              roles['Min Inventory By States'] &&
                (roles['Min Inventory By States'] === "Edit" || roles['Min Inventory By States'] === "View")
                // ? <MinInventoryByStates />
                ? <RecommendedInventoriesDataTable />
                // ? <InventoryTable />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_minInventoryByStates"
            element={
              roles['Min Inventory By States'] &&
                (roles['Min Inventory By States'] === "Edit")
                ? <MinInventoryByStates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_minInventoryByStates/:id"
            element={
              roles['Min Inventory By States'] &&
                (roles['Min Inventory By States'] === "Edit")
                ? <MinInventoryByStates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_minInventoryByStates/:id"
            element={
              roles['Min Inventory By States'] &&
                (roles['Min Inventory By States'] === "Edit" || roles['Min Inventory By States'] === "View")
                ? <MinInventoryByStates />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/categories"
            element={
              roles['Categories'] &&
                (roles['Categories'] === "Edit" || roles['Categories'] === "View")
                ? <Categories />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_categories"
            element={
              roles['Categories'] &&
                (roles['Categories'] === "Edit")
                ? <Categories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_categories/:id"
            element={
              roles['Categories'] &&
                (roles['Categories'] === "Edit")
                ? <Categories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_categories/:id"
            element={
              roles['Categories'] &&
                (roles['Categories'] === "Edit" || roles['Categories'] === "View")
                ? <Categories />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/products"
            element={
              roles['Product Directory'] &&
                (roles['Product Directory'] === "Edit" || roles['Product Directory'] === "View")
                ? <Products />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/products/:id"
            element={
              roles['Product Directory'] &&
                (roles['Product Directory'] === "Edit" || roles['Product Directory'] === "View")
                ? <Products />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_products"
            element={
              roles['Product Directory'] &&
                (roles['Product Directory'] === "Edit")
                ? <Products />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_products/:id"
            element={
              roles['Product Directory'] &&
                (roles['Product Directory'] === "Edit")
                ? <Products />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_products/:id"
            element={
              roles['Product Directory'] &&
                (roles['Product Directory'] === "Edit" || roles['Product Directory'] === "View")
                ? <Products />
                : <ErrorNotFound />
            }
          />


          <Route
            path="/storageUnitItemss"
            element={
              roles["Storage Unit Items"] &&
                (roles["Storage Unit Items"] === "Edit" || roles["Storage Unit Items"] === "View")
                ? <ActiveCompanies />
                : <ActiveCompanies />
            }
          />

          <Route
            path="/autoRecall"
            element={
              roles["Recall"] &&
                (roles["Recall"] === "Edit" || roles["Recall"] === "View")
                ? <AutoRecalls />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/paymentss"
            element={
              roles['Payments'] &&
                (roles['Payments'] === "Edit" || roles['Payments'] === "View")
                ? <CompanyPayments />
                : <ErrorNotFound />
            } />


          <Route exact
            path="/storageUnitItems"
            element={
              roles['Storage Unit Items'] &&
                (roles['Storage Unit Items'] === "Edit" || roles['Storage Unit Items'] === "View")
                ? <StorageUnitItems />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_storageUnitItems"
            element={
              roles['Storage Unit Items'] &&
                (roles['Storage Unit Items'] === "Edit")
                ? <StorageUnitItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_storageUnitItems/:id"
            element={
              roles['Storage Unit Items'] &&
                (roles['Storage Unit Items'] === "Edit")
                ? <StorageUnitItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_storageUnitItems/:id"
            element={
              roles['Storage Unit Items'] &&
                (roles['Storage Unit Items'] === "Edit" || roles['Storage Unit Items'] === "View")
                ? <StorageUnitItems />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/productQuantities"
            element={
              roles['Product Quantities'] &&
                (roles['Product Quantities'] === "Edit" || roles['Product Quantities'] === "View")
                ? <ProductQuantities />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_productQuantities"
            element={
              roles['Product Quantities'] &&
                (roles['Product Quantities'] === "Edit")
                ? <ProductQuantities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_productQuantities/:id"
            element={
              roles['Product Quantities'] &&
                (roles['Product Quantities'] === "Edit")
                ? <ProductQuantities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_productQuantities/:id"
            element={
              roles['Product Quantities'] &&
                (roles['Product Quantities'] === "Edit" || roles['Product Quantities'] === "View")
                ? <ProductQuantities />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/inventoryCheckss"
            element={
              roles['Inventory Check List'] &&
                (roles['Inventory Check List'] === "Edit" || roles['Inventory Check List'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            }
          />


          <Route exact
            path="/inventoryChecks"
            element={
              roles['Inventory Check List'] &&
                (roles['Inventory Check List'] === "Edit" || roles['Inventory Check List'] === "View")
                ? <InventoryCheckListsCount />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_inventoryCheckLists"
            element={
              roles['Inventory Check Lists'] &&
                (roles['Inventory Check Lists'] === "Edit")
                ? <InventoryCheckLists />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_inventoryCheckLists/:id"
            element={
              roles['Inventory Check Lists'] &&
                (roles['Inventory Check Lists'] === "Edit")
                ? <InventoryCheckLists />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_inventoryCheckLists/:id"
            element={
              roles['Inventory Check Lists'] &&
                (roles['Inventory Check Lists'] === "Edit" || roles['Inventory Check Lists'] === "View")
                ? <InventoryCheckLists />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/inventoryCheckLists"
            element={
              roles['Inventory Check Lists'] &&
                (roles['Inventory Check Lists'] === "Edit" || roles['Inventory Check Lists'] === "View")
                ? <InventoryCheckLists />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_inventoryCheckLists"
            element={
              roles['Inventory Check Lists'] &&
                (roles['Inventory Check Lists'] === "Edit")
                ? <InventoryCheckLists />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_inventoryCheckLists/:id"
            element={
              roles['Inventory Check Lists'] &&
                (roles['Inventory Check Lists'] === "Edit")
                ? <InventoryCheckLists />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_inventoryCheckLists/:id"
            element={
              roles['Inventory Check Lists'] &&
                (roles['Inventory Check Lists'] === "Edit" || roles['Inventory Check Lists'] === "View")
                ? <InventoryCheckLists />
                : <ErrorNotFound />
            }
          />

          {/* <Route exact
            path="/Orders Count"
            element={
              roles['Orders Count'] &&
                (roles['Orders Count'] === "Edit" || roles['Orders Count'] === "View")
                ? <OrdersCount />
                : <OrdersCount />
            }
          /> */}

          <Route exact
            path="/expirationItemss"
            element={
              roles['Expiration Items'] &&
                (roles['Expiration Items'] === "Edit" || roles['Expiration Items'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/expirationItems"
            element={
              roles['Expiration Items'] &&
                (roles['Expiration Items'] === "Edit" || roles['Expiration Items'] === "View")
                ? <ExpirationItems />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_expirationItems"
            element={
              roles['Expiration Items'] &&
                (roles['Expiration Items'] === "Edit")
                ? <ExpirationItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_expirationItems/:id"
            element={
              roles['Expiration Items'] &&
                (roles['Expiration Items'] === "Edit")
                ? <ExpirationItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_expirationItems/:id"
            element={
              roles['Expiration Items'] &&
                (roles['Expiration Items'] === "Edit" || roles['Expiration Items'] === "View")
                ? <ExpirationItems />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/expiredItems"
            element={
              roles['Expired Items'] &&
                (roles['Expired Items'] === "Edit" || roles['Expired Items'] === "View")
                ? <ExpiredItems />
                : <ErrorNotFound />
            } />

          <Route exact
            path="/expiredItems/:id"
            element={
              roles['Expired Items'] &&
                (roles['Expired Items'] === "Edit" || roles['Expired Items'] === "View")
                ? <ExpiredItems />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_expiredItems"
            element={
              roles['Expired Items'] &&
                (roles['Expired Items'] === "Edit")
                ? <ExpiredItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_expiredItems/:id"
            element={
              roles['Expired Items'] &&
                (roles['Expired Items'] === "Edit")
                ? <ExpiredItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_expiredItems/:id"
            element={
              roles['Expired Items'] &&
                (roles['Expired Items'] === "Edit" || roles['Expired Items'] === "View")
                ? <ExpiredItems />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/missingItemsCount"
            element={
              roles['Missing Items'] &&
                (roles['Missing Items'] === "Edit" || roles['Missing Items'] === "View")
                ? <MissingItemsCount />
                : <ErrorNotFound />
            } />
          {/* //OrdersCount */}
          <Route exact
            path="/ordersCount"
            element={
              roles['Orders Count'] &&
                (roles['Orders'] === "Edit" || roles['Orders'] === "View")
                ? <OrdersCount />
                : <OrdersCount />
            } />
          <Route exact
            path="/missingItemsCount"
            element={
              roles['Missing Items'] &&
                (roles['Missing Items'] === "Edit" || roles['Missing Items'] === "View")
                ? <MissingItemsCount />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_missingItems"
            element={
              roles['Missing Items'] &&
                (roles['Missing Items'] === "Edit")
                ? <MissingItems />
                : <ErrorNotFound />
            }
          />


          <Route
            path="/edit_missingItems/:id"
            element={
              roles['Missing Items'] &&
                (roles['Missing Items'] === "Edit")
                ? <MissingItems />
                : <ErrorNotFound />
            }
          />



          <Route
            path="/view_missingItems/:id"
            element={
              roles['Missing Items'] &&
                (roles['Missing Items'] === "Edit" || roles['Missing Items'] === "View")
                ? <MissingItems />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/missingItems"
            element={
              roles['MissingItems'] &&
                (roles['MissingItems'] === "Edit" || roles['MissingItems'] === "View")
                ? <MissingItems />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/missingItems:id"
            element={
              roles['MissingItems'] &&
                (roles['MissingItems'] === "Edit" || roles['MissingItems'] === "View")
                ? <MissingItems />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/missingItems/:id"
            element={
              roles['MissingItems'] &&
                (roles['MissingItems'] === "Edit" || roles['MissingItems'] === "View")
                ? <MissingItems />
                : <ErrorNotFound />
            } />


          <Route
            path="/create_missingItems"
            element={
              roles['MissingItems'] &&
                (roles['MissingItems'] === "Edit")
                ? <MissingItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_missingItems/:id"
            element={
              roles['MissingItems'] &&
                (roles['MissingItems'] === "Edit")
                ? <MissingItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_missingItems/:id"
            element={
              roles['MissingItems'] &&
                (roles['MissingItems'] === "Edit" || roles['MissingItems'] === "View")
                ? <MissingItems />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/recallItems"
            element={
              roles['Recall Items'] &&
                (roles['Recall Items'] === "Edit" || roles['Recall Items'] === "View")
                ? <RecallItems />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/recallItems/:id"
            element={
              roles['Recall Items'] &&
                (roles['Recall Items'] === "Edit" || roles['Recall Items'] === "View")
                ? <RecallItems />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_recallItems"
            element={
              roles['Recall Items'] &&
                (roles['Recall Items'] === "Edit")
                ? <RecallItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_recallItems/:id"
            element={
              roles['Recall Items'] &&
                (roles['Recall Items'] === "Edit")
                ? <RecallItems />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_recallItems/:id"
            element={
              roles['Recall Items'] &&
                (roles['Recall Items'] === "Edit" || roles['Recall Items'] === "View")
                ? <RecallItems />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/itemsRecalleds"
            element={
              roles['Items Recalleds'] &&
                (roles['Items Recalleds'] === "Edit" || roles['Items Recalleds'] === "View")
                ? <ItemsRecalleds />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_itemsRecalleds"
            element={
              roles['Items Recalleds'] &&
                (roles['Items Recalleds'] === "Edit")
                ? <ItemsRecalleds />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_itemsRecalleds/:id"
            element={
              roles['Items Recalleds'] &&
                (roles['Items Recalleds'] === "Edit")
                ? <ItemsRecalleds />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_itemsRecalleds/:id"
            element={
              roles['Items Recalleds'] &&
                (roles['Items Recalleds'] === "Edit" || roles['Items Recalleds'] === "View")
                ? <ItemsRecalleds />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/recalls"
            element={
              roles['Recalls'] &&
                (roles['Recalls'] === "Edit" || roles['Recalls'] === "View")
                ? <Recalls />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_recalls"
            element={
              roles['Recalls'] &&
                (roles['Recalls'] === "Edit")
                ? <Recalls />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_recalls/:id"
            element={
              roles['Recalls'] &&
                (roles['Recalls'] === "Edit")
                ? <Recalls />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_recalls/:id"
            element={
              roles['Recalls'] &&
                (roles['Recalls'] === "Edit" || roles['Recalls'] === "View")
                ? <Recalls />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/storageUnitChecks"
            element={
              roles['Storage Unit Checks'] &&
                (roles['Storage Unit Checks'] === "Edit" || roles['Storage Unit Checks'] === "View")
                ? <StorageUnitChecks />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/storageUnitChecks/:id"
            element={
              roles['Storage Unit Checks'] &&
                (roles['Storage Unit Checks'] === "Edit" || roles['Storage Unit Checks'] === "View")
                ? <StorageUnitChecks />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_storageUnitChecks"
            element={
              roles['Storage Unit Checks'] &&
                (roles['Storage Unit Checks'] === "Edit")
                ? <StorageUnitChecks />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_storageUnitChecks/:id"
            element={
              roles['Storage Unit Checks'] &&
                (roles['Storage Unit Checks'] === "Edit")
                ? <StorageUnitChecks />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_storageUnitChecks/:id"
            element={
              roles['Storage Unit Checks'] &&
                (roles['Storage Unit Checks'] === "Edit" || roles['Storage Unit Checks'] === "View")
                ? <StorageUnitChecks />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/batteryChecks"
            element={
              roles['Battery Checks'] &&
                (roles['Battery Checks'] === "Edit" || roles['Battery Checks'] === "View")
                ? <BatteryChecks />
                : <ErrorNotFound />
            } />

          <Route exact
            path="/batteryChecks/:id"
            element={
              roles['Battery Checks'] &&
                (roles['Battery Checks'] === "Edit" || roles['Battery Checks'] === "View")
                ? <BatteryChecks />
                : <ErrorNotFound />
            } />



          <Route
            path="/create_batteryChecks"
            element={
              roles['Battery Checks'] &&
                (roles['Battery Checks'] === "Edit")
                ? <BatteryChecks />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_batteryChecks/:id"
            element={
              roles['Battery Checks'] &&
                (roles['Battery Checks'] === "Edit")
                ? <BatteryChecks />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_batteryChecks/:id"
            element={
              roles['Battery Checks'] &&
                (roles['Battery Checks'] === "Edit" || roles['Battery Checks'] === "View")
                ? <BatteryChecks />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/facilityStaffs"
            element={
              roles['Facility Staffs'] &&
                (roles['Facility Staffs'] === "Edit" || roles['Facility Staffs'] === "View")
                ? <FacilityStaffs />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_facilityStaffs"
            element={
              roles['Facility Staffs'] &&
                (roles['Facility Staffs'] === "Edit")
                ? <FacilityStaffs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_facilityStaffs/:id"
            element={
              roles['Facility Staffs'] &&
                (roles['Facility Staffs'] === "Edit")
                ? <FacilityStaffs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_facilityStaffs/:id"
            element={
              roles['Facility Staffs'] &&
                (roles['Facility Staffs'] === "Edit" || roles['Facility Staffs'] === "View")
                ? <FacilityStaffs />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/orders"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit" || roles['Orders'] === "View")
                ? <Orders />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_orders"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit")
                ? <Orders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_orders/:id"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit")
                ? <Orders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_orders/:id"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit" || roles['Orders'] === "View")
                ? <Orders />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/placedOrders"
            element={
              roles['Placed Orders'] &&
                (roles['Placed Orders'] === "Edit" || roles['Placed Orders'] === "View")
                ? <PlacedOrders />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_placedOrders"
            element={
              roles['Placed Orders'] &&
                (roles['Placed Orders'] === "Edit")
                ? <PlacedOrders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_placedOrders/:id"
            element={
              roles['Placed Orders'] &&
                (roles['Placed Orders'] === "Edit")
                ? <PlacedOrders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_placedOrders/:id"
            element={
              roles['Placed Orders'] &&
                (roles['Placed Orders'] === "Edit" || roles['Placed Orders'] === "View")
                ? <PlacedOrders />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/fulfilledOrders"
            element={
              roles['Fulfilled Orders'] &&
                (roles['Fulfilled Orders'] === "Edit" || roles['Fulfilled Orders'] === "View")
                ? <FulfilledOrders />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_fulfilledOrders"
            element={
              roles['Fulfilled Orders'] &&
                (roles['Fulfilled Orders'] === "Edit")
                ? <FulfilledOrders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_fulfilledOrders/:id"
            element={
              roles['Fulfilled Orders'] &&
                (roles['Fulfilled Orders'] === "Edit")
                ? <FulfilledOrders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_fulfilledOrders/:id"
            element={
              roles['Fulfilled Orders'] &&
                (roles['Fulfilled Orders'] === "Edit" || roles['Fulfilled Orders'] === "View")
                ? <FulfilledOrders />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/requiredInventories"
            element={
              roles['Required Inventories'] &&
                (roles['Required Inventories'] === "Edit" || roles['Required Inventories'] === "View")
                ? <RequiredInventories />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_requiredInventories"
            element={
              roles['Required Inventories'] &&
                (roles['Required Inventories'] === "Edit")
                ? <RequiredInventories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_requiredInventories/:id"
            element={
              roles['Required Inventories'] &&
                (roles['Required Inventories'] === "Edit")
                ? <RequiredInventories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_requiredInventories/:id"
            element={
              roles['Required Inventories'] &&
                (roles['Required Inventories'] === "Edit" || roles['Required Inventories'] === "View")
                ? <RequiredInventories />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/minInventoryByProducts"
            element={
              roles['Min Inventory By Products'] &&
                (roles['Min Inventory By Products'] === "Edit" || roles['Min Inventory By Products'] === "View")
                ? <MinInventoryByProducts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_minInventoryByProducts"
            element={
              roles['Min Inventory By Products'] &&
                (roles['Min Inventory By Products'] === "Edit")
                ? <MinInventoryByProducts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_minInventoryByProducts/:id"
            element={
              roles['Min Inventory By Products'] &&
                (roles['Min Inventory By Products'] === "Edit")
                ? <MinInventoryByProducts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_minInventoryByProducts/:id"
            element={
              roles['Min Inventory By Products'] &&
                (roles['Min Inventory By Products'] === "Edit" || roles['Min Inventory By Products'] === "View")
                ? <MinInventoryByProducts />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/distributors"
            element={
              roles['Distributors'] &&
                (roles['Distributors'] === "Edit" || roles['Distributors'] === "View")
                ? <Distributors />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_distributors"
            element={
              roles['Distributors'] &&
                (roles['Distributors'] === "Edit")
                ? <Distributors />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_distributors/:id"
            element={
              roles['Distributors'] &&
                (roles['Distributors'] === "Edit")
                ? <Distributors />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_distributors/:id"
            element={
              roles['Distributors'] &&
                (roles['Distributors'] === "Edit" || roles['Distributors'] === "View")
                ? <Distributors />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/helps"
            element={
              roles['Helps'] &&
                (roles['Helps'] === "Edit" || roles['Helps'] === "View")
                ? <Helps />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_helps"
            element={
              roles['Helps'] &&
                (roles['Helps'] === "Edit")
                ? <Helps />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_helps/:id"
            element={
              roles['Helps'] &&
                (roles['Helps'] === "Edit")
                ? <Helps />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_helps/:id"
            element={
              roles['Helps'] &&
                (roles['Helps'] === "Edit" || roles['Helps'] === "View")
                ? <Helps />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/recommendedInventories"
            element={
              roles['Recommended Inventories'] &&
                (roles['Recommended Inventories'] === "Edit" || roles['Recommended Inventories'] === "View")
                ? <RecommendedInventories />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_recommendedInventories"
            element={
              roles['Recommended Inventories'] &&
                (roles['Recommended Inventories'] === "Edit")
                ? <RecommendedInventories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_recommendedInventories/:id"
            element={
              roles['Recommended Inventories'] &&
                (roles['Recommended Inventories'] === "Edit")
                ? <RecommendedInventories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_recommendedInventories/:id"
            element={
              roles['Recommended Inventories'] &&
                (roles['Recommended Inventories'] === "Edit" || roles['Recommended Inventories'] === "View")
                ? <RecommendedInventories />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/companyUsers"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <CompanyUsers />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_companyUsers"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit")
                ? <CompanyUsers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_companyUsers/:id"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit")
                ? <CompanyUsers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_companyUsers/:id"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <CompanyUsers />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/alerts"
            element={
              roles['Alerts'] &&
                (roles['Alerts'] === "Edit" || roles['Alerts'] === "View")
                ? <Alerts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_alerts"
            element={
              roles['Alerts'] &&
                (roles['Alerts'] === "Edit")
                ? <Alerts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_alerts/:id"
            element={
              roles['Alerts'] &&
                (roles['Alerts'] === "Edit")
                ? <Alerts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_alerts/:id"
            element={
              roles['Alerts'] &&
                (roles['Alerts'] === "Edit" || roles['Alerts'] === "View")
                ? <Alerts />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/helps"
            element={
              roles['Help'] &&
                (roles['Help'] === "Edit" || roles['Help'] === "View")
                ? <EmployeeRoles />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_helps"
            element={
              roles['Help'] &&
                (roles['Help'] === "Edit")
                ? <EmployeeRoles />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_helps/:id"
            element={
              roles['Help'] &&
                (roles['Help'] === "Edit")
                ? <EmployeeRoles />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_helps/:id"
            element={
              roles['Help'] &&
                (roles['Help'] === "Edit" || roles['Help'] === "View")
                ? <EmployeeRoles />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/tickets"
            element={
              roles['Tickets'] &&
                (roles['Tickets'] === "Edit" || roles['Tickets'] === "View")
                ? <Tickets />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_tickets"
            element={
              roles['Tickets'] &&
                (roles['Tickets'] === "Edit")
                ? <Tickets />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_tickets/:id"
            element={
              roles['Tickets'] &&
                (roles['Tickets'] === "Edit")
                ? <Tickets />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_tickets/:id"
            element={
              roles['Tickets'] &&
                (roles['Tickets'] === "Edit" || roles['Tickets'] === "View")
                ? <Tickets />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/notifications"
            element={
              roles['Notifications'] &&
                (roles['Notifications'] === "Edit" || roles['Notifications'] === "View")
                ? <Notifications />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_notifications"
            element={
              roles['Notifications'] &&
                (roles['Notifications'] === "Edit")
                ? <Notifications />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_notifications/:id"
            element={
              roles['Notifications'] &&
                (roles['Notifications'] === "Edit")
                ? <Notifications />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_notifications/:id"
            element={
              roles['Notifications'] &&
                (roles['Notifications'] === "Edit" || roles['Notifications'] === "View")
                ? <Notifications />
                : <ErrorNotFound />
            }
          />

          {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

          {/* <Route path="/settings" element={Settings} /> */}
          {/* <Route path="/adminSettings" element={AdminSettings} /> */}
          <Route
            path="/adminSettings"
            element={
              config.displaySettings
                ? <AdminSettings />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/uploads"
            element={
              roles["Upload Histories"] &&
                (roles["Upload Histories"] === "Edit" || roles["Upload Histories"] === "View")
                ? <Uploads />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities"
            element={
              roles["Activities"] &&
                (roles["Activities"] === "Edit" || roles["Activities"] === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities/:id"
            element={
              roles["Activities"] &&
                (roles["Activities"] === "Edit" || roles["Activities"] === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/employeeRoles"
            element={
              roles["Employee Roles"] &&
                (roles["Employee Roles"] === "Edit" || roles["Employee Roles"] === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_employeeRoles"
            element={
              roles["Employee Roles"] &&
                (roles["Employee Roles"] === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_employeeRoles/:id"
            element={
              roles["Employee Roles"] &&
                (roles["Employee Roles"] === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_employeeRoles/:id"
            element={
              roles["Employee Roles"] &&
                (roles["Employee Roles"] === "Edit" || roles["Employee Roles"] === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/emailStatuses"
            element={
              roles["Email Statuses"] &&
                (roles["Email Statuses"] === "Edit" || roles["Email Statuses"] === "View")
                ? <EmailStatus />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
           <Route
            path="/deviceManagements"
            element={
              roles["Device Managements"] &&
                (roles["Device Managements"] === "Edit")
                ? <DeviceManagements />
                : <ErrorNotFound />
            }
          />
                     <Route
            path="/locationTrackers"
            element={
              roles["Location Trackers"] &&
                (roles["Location Trackers"] === "Edit")
                ? <LocationTrackers />
                : <ErrorNotFound />
            }
          />
                     <Route
            path="/accessPoints"
            element={
              roles["Access Points"] &&
                (roles["Access Points"] === "Edit")
                ? <AccessPoints />
                : <ErrorNotFound />
            }
          />

          {/* Super Admin Active Companys */}
          <Route exact
            path="/recallItemss"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/storageUnitCheckss"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/batteryCheckss"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/activitiess"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />

          <Route exact
            path="/facilityStaffss"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/ordersCounts"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/categoriess"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/requiredInventoriess"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/distributorss"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/missingItemsCounts"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />
          <Route exact
            path="/productss"
            element={
              roles['Companies'] &&
                (roles['Companies'] === "Edit" || roles['Companies'] === "View")
                ? <ActiveCompanies />
                : <ErrorNotFound />
            } />

          {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
        </Routes >
        <Outlet />
      </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />

  </Routes>

);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />} />

        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
