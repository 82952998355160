import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DeviceManagements from './components/DeviceManagements';

const deviceManagements = (props,{ t }) => (
  <Container>
    <DeviceManagements {...props} />
  </Container>
);

deviceManagements.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(deviceManagements);
